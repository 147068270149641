import React, { useEffect } from "react";
import "./styles.scss";
import { OffreComp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getOffresRec } from "../../actions/reservation";

export default function OffresPage() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { list_rec_offres } = useSelector((state) => state.reservation);

  useEffect(() => {
    dispatch(getOffresRec(user?.user?.id));

    const intervalId = setInterval(() => {
      dispatch(getOffresRec(user?.user?.id));
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="mes-offres-container">
      {list_rec_offres?.map((item, index) => (
        <div className="offre-item" key={index}>
          <span>{formatDate(item?.created_at)}</span>
          <OffreComp data={item} />
        </div>
      ))}
      {list_rec_offres?.length == 0 && (
        <span style={{ margin: "auto" }}>Pas d'offres reçues !</span>
      )}

      {/* <div className='offre-item'>
            <span>12/06/2001</span>
            <OffreComp/>
        </div> */}
    </div>
  );
}
