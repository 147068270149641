import React from "react";
import "./styles.scss";
import {KleeverAuthBanner, Line} from "../../assets/images";
import {Button} from "react-bootstrap";
import {NavHeader} from "../../components";
import {useHistory} from "react-router-dom";

export default function AuthPage() {
    const history = useHistory();
    const handleNavigation = (e) => {
        e.preventDefault();
        history.push('/phone')
    }
    const handleNav = (e)=>{
        e.preventDefault() ; 
        history.push('/signinpage')
    }
    return (
        <div className="auth-container">
            <div className="auth-banner">
                {< NavHeader />}
            </div>
            <div className="auth-control">
                <div className="connexion-btns">
                    <div className="col-md-6 text-div">
                        <h3>Connexion</h3>
                        <img src={Line} alt={'line-img'}/>
                    </div>
                    <div className="btn-div">
                        <Button
                        onClick={(e)=>handleNav(e)}
                        >
                            Se connecter</Button>
                        <Button onClick={(e) => handleNavigation(e)}>Je suis nouveau</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
