import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Bus,
  Car,
  Scooter,
  Truck,
} from "../../assets/images";
import { UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons";
import { SideBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnnonceListe } from "../../actions/annonce";
import Loader from "react-js-loader";
import { typevhchoice } from "../../actions/global";

export default function ChoosePage() {
  const history = useHistory();
  const [vhindex, setVhindex] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const ChoiceData = [
    {
      title: "Voiture",
      image: Car,
      banner: Banner3,
      type: "voiture",
    },
    {
      title: "Car / Bus",
      image: Bus,
      banner: Banner1,
      type: "bus",
    },
    {
      title: "Camion",
      image: Truck,
      banner: Banner4,
      type: "camion",
    },
    {
      title: "Moto",
      image: Scooter,
      banner: Banner2,
      type: "moto",
    },
  ];
  const handlevhindex = (c) => {
    switch (c) {
      case "-":
        if (vhindex === 0) {
          setVhindex(3);
        } else {
          setVhindex(vhindex - 1);
        }
        break;
      case "+":
        if (vhindex === 3) {
          setVhindex(0);
        } else {
          setVhindex(vhindex + 1);
        }
        break;
      default:
        break;
    }
  };

  const handleNav = (item) => {
    setLoading(true);
    dispatch(typevhchoice(item));
    // history.push("/showall/" + item);

    if (item === "camion" || item === "bus") {
      history.push("/selectpop/" + item);
      // dispatch(getAnnonceListe({ type_de_vh: item, user_id: user?.user?.id })) // get the choosen type
      //   .then(() => {
      //     history.push("/selectpop/" + item);
      //   })
      //   .catch(() => setLoading(false));
    } else {
      dispatch(getAnnonceListe({ type_de_vh: item, user_id: user?.user?.id })) // get the choosen type
        .then(() => {
          history.push("/showall/" + item +"/page=1");
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div>
      {loading && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
      <div className="container-fluid choose-container">
      <SideBar btn />

        <div
          className="choose-banner"
          style={{
            backgroundImage: `url(${ChoiceData[vhindex]?.banner})`,
          }}
        >
        </div>
        <div className="choose-control">
          <p>
            {`Louer ${
              ChoiceData[vhindex]?.type === "bus" ||
              ChoiceData[vhindex]?.type === "camion"
                ? "un "
                : "une "
            }`}
            <span id="type-span">{ChoiceData[vhindex]?.title}</span>
          </p>
          <div className="swipe-case">
            <button className="btn">
              <UilArrowLeft size="15" onClick={() => handlevhindex("-")} />
            </button>
            <img
              className={ChoiceData[vhindex]?.type}
              src={ChoiceData[vhindex]?.image}
              alt=""
            />
            <button className="btn">
              <UilArrowRight size="15" onClick={() => handlevhindex("+")} />
            </button>
          </div>
          <button
            className="btn ok-btn"
            onClick={() => handleNav(ChoiceData[vhindex]?.type)}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
}
