import { Bus1, Bus2, Bus3, Bus4, Golf, MercedesTruck, Moto1, NissanTruck, Truck, Truck2, Truck3, VolvoTruck, ford, mercedes, nissan ,Moto2,Moto4,Moto3} from "../assets/images";
import { ADD_VHEICULE, EDIT_VHEICULE } from "../constants/actions";
const INITIAL_STATE = {
    vheicule:[
        {v_id:'1',type:'voiture',marque:'nissan',tarif:'26',image:[Golf,nissan,mercedes,],notes:'loremipsum'},
        {v_id:'2',type:'voiture',marque:'mercedes',tarif:'26',image:[mercedes,Golf,nissan],notes:'loremipsum'},
        {v_id:'3',type:'voiture',marque:'ford',tarif:'26',image:[nissan,mercedes,Golf],notes:'loremipsum'},
        {v_id:'4',type:'voirure',marque:'golf',tarif:'26',image:[nissan,mercedes,Golf],notes:'loremipsum'},
        {v_id:'5',type:'camion',marque:'nisssan',tarif:'26',image:[NissanTruck,Truck2,MercedesTruck],notes:'loremipsum'},
        {v_id:'6',type:'camion',marque:'mercedes',tarif:'26',image:[Truck2,MercedesTruck,NissanTruck],notes:'loremipsum'},
        {v_id:'7',type:'camion',marque:'Volvo',tarif:'26',image:[NissanTruck,Truck2,Truck3],notes:'loremipsum'},
        {v_id:'8',type:'camion',marque:'mercedes',tarif:'26',image:[NissanTruck,Truck2,Truck3],notes:'loremipsum'},
        {v_id:'9',type:'bus',marque:'volvo E220 Ds',tarif:'26',image:[Bus2,Bus3,Bus4],notes:'loremipsum'},
        {v_id:'10',type:'bus',marque:'Mercedes Wrangler 23F',tarif:'26',image:[Bus1,Bus4,Bus2],notes:'loremipsum'},
        {v_id:'11',type:'bus',marque:'BEEN 23F',tarif:'26',image:[Bus4,Bus3,Bus1],notes:'loremipsum'},
        {v_id:'12',type:'moto',marque:'Scania 23F',tarif:'26',image:[Moto1,Moto2,Moto3],notes:'loremipsum'},
        {v_id:'13',type:'moto',marque:'volvo E220 Ds',tarif:'26',image:[Moto2,Moto3,Moto4],notes:'loremipsum'},
        {v_id:'14',type:'moto',marque:'Mercedes Wrangler 23F',tarif:'26',image:[Moto1,Moto4,Moto2],notes:'loremipsum'},
        {v_id:'15',type:'moto',marque:'BEEN 23F',tarif:'26',image:[Moto4,Moto3,Moto1],notes:'loremipsum'},
        // {v_id:'16',type:'b',marque:'Scania 23F',tarif:'26',image:[Bus1,Bus2,Bus3],notes:'loremipsum'},
    ]
};
function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
     case  ADD_VHEICULE : 
        return {...state,vheicule:action.payload}
    case EDIT_VHEICULE : 
        return {...state,vheicule:action.payload}
        default:
            return state;
    }
}
export default reducer;