import React from "react";
import "./style.scss";

export default function PrivacyPage() {
  return (
    <div className="privacy-comp">
      <div class="mx-auto my-5 col-lg-12">
        <div style={{textAlign: 'justify'}}>
          <p>
            <strong>&nbsp;À propos de cet avis</strong>
          </p>
          <br />
          <p>
            Cette notification de confidentialité et de cookies fournit
            des informations sur la façon dont KLEEVER&nbsp; collecte et
            traite vos données personnelles lorsque vous visitez notre site Web
            ou nos applications mobiles.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Les données que nous collectons sur vous&nbsp;</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Nous pouvons collecter, utiliser, stocker et
            transférer les différents types de données personnelles suivantes
            vous concernant:
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Informations que vous nous fournissez:</strong> nous
            recevons et stockons les informations que vous nous fournissez, y
            compris vos données d'identité, vos coordonnées, votre adresse de
            livraison .
          </p>
          <p>
            Informations sur votre utilisation de notre site Web et / ou de nos
            applications mobiles: nous collectons et stockons automatiquement
            certains types d'informations concernant votre utilisation du site
            KLEEVER, y compris des informations sur vos
            recherches, vues, téléchargements et achats.
          </p>
          <p>
            Informations provenant de tiers et de sources accessibles au public:
            nous pouvons recevoir des informations vous concernant de tiers, y
            compris nos transporteurs; prestataires de services de paiement;
            marchands / marques; et prestataires de services publicitaires.
          </p>
          <br />
          <p>
            <strong>Les cookies et comment nous les utilisons</strong>
          </p>
          <p>
            Un cookie est un petit fichier de lettres et de chiffres que nous
            mettons sur votre ordinateur si vous y consentez.&nbsp;
          </p>
          <p>
            Les cookies nous permettent de vous distinguer des autres
            utilisateurs de notre site Web et de nos applications mobiles, ce
            qui nous aide à vous offrir une expérience de navigation améliorée.
          </p>
          <p>Par exemple, nous utilisons des cookies aux fins suivantes:</p>
          <p>&nbsp;</p>
          <p>
            Reconnaître et compter le nombre de visiteurs et voir comment les
            visiteurs se déplacent sur le site lorsqu'ils l'utilisent (cela nous
            aide à améliorer le fonctionnement de notre site Web, par exemple en
            nous assurant que les utilisateurs peuvent trouver ce qu'ils
            recherchent).
          </p>
          <p>
            Envoi de newsletters et de messages commerciaux / publicitaires
            adaptés à vos intérêts.
          </p>
          <p>
            Nos tiers agréés peuvent également définir des cookies lorsque vous
            utilisez notre site web. Les tiers incluent les
            moteurs de recherche, les fournisseurs de services de mesure et
            d'analyse, les réseaux sociaux et les sociétés de publicité.
          </p>
          <br />
          <p>
            <strong>Comment nous utilisons vos données personnelles</strong>
          </p>
          <p>
            &nbsp;Nous utilisons vos données personnelles pour exploiter,
            fournir, développer et améliorer les services que nous
            proposons, notamment les suivants:
          </p>
          <p>&nbsp;Vous inscrire en tant que nouveau client.</p>
          <p>Gérer votre relation avec nous.</p>
          <p>
            Vous permettre de participer à des promotions, des concours et des
            sondages.&nbsp;
          </p>
          <p>
            Amélioration de notre site Web, de nos applications, et services.
          </p>
          <p>
            Respecter nos obligations légales, y compris vérifier votre identité
            si nécessaire.&nbsp;&nbsp;
          </p>
          <p>Détecter les fraudes.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Sécurité des données</strong>
          </p>
          <p>
            &nbsp;Nous avons mis en place des mesures de sécurité appropriées
            pour éviter que vos données personnelles ne soient accidentellement
            perdues, utilisées ou consultées de manière non autorisée, modifiées
            ou divulguées.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            En outre, nous limitons l'accès à vos données personnelles aux
            employés, agents, sous-traitants et autres tiers qui ont besoin de
            les connaître pour exécuter leurs obligations. Ils ne traiteront vos
            données personnelles que sur nos instructions et sont soumis à un
            devoir de confidentialité.
          </p>
          <p>&nbsp;</p>
          <p>
            Nous avons mis en place des procédures pour faire face à toute
            violation présumée de données personnelles et vous informerons ainsi
            que tout organisme de réglementation compétent, de toute violation
            lorsque nous sommes légalement tenus de le faire.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Vos droits légaux&nbsp;</strong>
          </p>
          <p>
            Il est important que les données personnelles que nous détenons à
            votre sujet soient exactes et à jour. Veuillez nous tenir informés
            si vos données personnelles changent pendant votre relation avec
            nous.
          </p>
          <p>
            &nbsp;Dans certaines circonstances, vous avez des droits en vertu
            des lois sur la protection des données en ce qui concerne vos
            données personnelles, y compris le droit d'accéder, de corriger ou
            de supprimer vos données personnelles et le droit de vous opposer ou
            de restreindre le traitement de vos données personnelles.&nbsp;
            &nbsp;
          </p>
          <br />
          <p>
            <strong>Suppression du compte</strong>
          </p>
          <p>
            &nbsp;La suppression d'un compte utilisateur sur notre application entraînera 
            la suppression définitive de toutes les annonces et réservations associées. 
            Cela garantit le respect du droit à l'effacement des données personnelles de l'utilisateur, 
            préservant ainsi sa vie privée conformément aux normes du RGPD..&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <br />
          <p>
            <strong>Section Assistance</strong>
          </p>
          <p>
          Nous contacter 24/7
          <br />00225 07-12-53-04-18
          contact@kleeverapp.com
          <br />
          Côte d'ivoire,Abidjan, Riviera Ciad, Banque BNI
          <br />
          ANGAMAN BUSINESS GROUP
          </p>
        </div>
      </div>
    </div>
  );
}
