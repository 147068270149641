import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';
import useDigitInput from 'react-digit-input';

export default function OtpInput({ setSmsCode }) {
  const [value, onChange] = useState('');
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 5,
    value,
    onChange,
  });

  const prevDigitsRef = useRef('');

  useEffect(() => {
    // Concatenate digit values into a single string
    const smsCodeValue = digits.map((digit) => digit.value).join('');

    // Only call setSmsCode if the concatenated value has changed
    if (prevDigitsRef.current !== smsCodeValue) {
      setSmsCode(smsCodeValue);
      prevDigitsRef.current = smsCodeValue;
    }
  }, [digits, setSmsCode]);

  return (
    <div className="otp-input">
      <div className="input-groupo">
        {digits.map((digit, index) => (
          <label key={index} htmlFor={`digit${index}`}>
            <div className="digit"></div>
            {digit.value ? <span>{digit.value}</span> : <hr />}
            <input
              id={`digit${index}`}
              inputMode="decimal"
              autoFocus={index === 0}
              {...digit}
            />
          </label>
        ))}
      </div>
    </div>
  );
}
