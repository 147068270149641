import { AUTHENTICATE_USER,SET_USER,LOGOUT_USER, PHONE_NUMBER, COUNTRY_USER, NOM_USER, LIST_DEP, INSC_NAME, INSC_PHONE, INSC_EMAIL, INSC_COUNTRY, INSC_CODE, INSC_PASSWORD, DATA_DEP_NOTES, DATA_DEP_ID } from "../constants/actions";

const INITIAL_STATE = {
    user: {},
    phoneNumber: '',
    nomUser: '',
    country: '',
    listDeps: [],
    inscr_name: '',
    inscr_phone: '',
    inscr_email: '',
    inscr_code: '',
    inscr_country: '',
    inscr_password: '',
    datadepnotes: [],
    id_dep: '',
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case PHONE_NUMBER:
            return {...state,phoneNumber:action.payload}   
        case NOM_USER:
            return {...state,nomUser:action.payload}   
        case COUNTRY_USER:
            return {...state,country:action.payload}   
        case AUTHENTICATE_USER:
            return {...state,user:action.payload}
        case SET_USER :
            return {...state,
                user: {
                  ...state.user,
                  user: action.payload
                }}     
        case LOGOUT_USER :
           return {...state,user:INITIAL_STATE.user}
        case LIST_DEP: 
            return {...state, listDeps:action.payload}
        case INSC_NAME:
            return {...state,inscr_name:action.payload}
        case INSC_PHONE:
            return {...state,inscr_phone:action.payload}
        case INSC_EMAIL:
            return {...state,inscr_email:action.payload}
        case INSC_COUNTRY:
            return {...state,inscr_country:action.payload}
        case INSC_CODE:
            return {...state,inscr_code:action.payload}
        case INSC_PASSWORD:
            return {...state,inscr_password:action.payload}
        case DATA_DEP_NOTES:
            return {...state,datadepnotes:action.payload}
        case DATA_DEP_ID:
            return {...state,id_dep:action.payload}
        default:
            return state;
    }
}
export default reducer;