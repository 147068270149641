import React from 'react'
import './styles.scss'

export default function NameInput({setName, nameuser}) {
  return (
  <input
  type='text' 
  className='name-input'
  value={nameuser}
  onChange={(e) => setName(e.target.value)}
/>        
  )
}
