import React from "react";
import "./styles.scss";
import DeppaneurCard from "../depanneurcard";
import { useSelector } from "react-redux";

export default function SwipeComp({depList}) {
  // const { listDeps } = useSelector((state) => state.user)
  return (
    <div className="container-swipe">
      <div className="mini-cont">
        {
          depList?.length > 0 ?
          depList?.map((item, index) => (
            <>
            <DeppaneurCard data={item} key={index}  />
            </>
          ))
          :
          "Il n'y a pas de dépanneur à proximité"
        }
      </div>
    </div>
  );
}
