import React, { useEffect, useState } from "react";
import "./styles.scss";
import { UilSearch, UilAngleDown } from "@iconscout/react-unicons";
import { ShowVheic } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UilFilter } from "@iconscout/react-unicons";
import {
  getAnnonceListe,
  getAnnonceListe2,
  getAnnonceListe3,
  getAnnonceListe4,
  getBigPriceOfVh,
  getBoiteVeOfVh,
  getChauffeurOfVh,
  getMarqueOfVh,
  getModelOfVh,
  getNbrPlaceOfVh,
  getSmallPriceOfVh,
  getTypeOfVh,
} from "../../actions/annonce";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "react-js-loader";
import SearchIcon from "@mui/icons-material/Search";
import { apiClient } from "../../actions/api";
import { FILTER_CHECK, FILTER_MAX, SEARCH_WORDS, VH_MARQUE } from "../../constants/actions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function ShowAllPage() {
  // const vheicule = useSelector((state) => state.vheicule.vheicule);
  const { liste_annonce } = useSelector((state) => state.annonce);
  const { genre_camion_sr, nbr_plc_sr, filter_check_ch } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const { pagenumber } = useParams();
  const [currentPage, setCurrentPage] = useState(parseInt(pagenumber) || 1);
  const { user } = useSelector((state) => state.user);
  const { typechoice, searchWords } = useSelector((state) => state.global)
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(null);
  const [filtredListe, setFiltredListe] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [paginationCount, setPaginationCount] = useState(Math.ceil(liste_annonce?.length / itemsPerPage));
  const {
    model,
    boite_de_vitess,
    nbr_places,
    type_de_vh,
    marque,
    chauffeur,
    big_prico,
    small_prico,
    filter_max_value
  } = useSelector((state) => state.annonce);
  const { vhmarque } = useSelector((state) => state.global);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentMesAnnonces = liste_annonce?.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  const handleChangePage = (_, newPage) => {
    history.push("/showall/"+typechoice+"/page="+newPage)
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNav = (e) => {
    e.preventDefault();

    dispatch(getTypeOfVh("voiture"));

    dispatch(getMarqueOfVh(""));

    dispatch(getNbrPlaceOfVh(""));

    dispatch(getModelOfVh(""));

    dispatch(getSmallPriceOfVh(""));

    dispatch(getBigPriceOfVh(""));

    dispatch(getChauffeurOfVh(""));

    dispatch({ type: VH_MARQUE, payload: '' });

    dispatch(getBoiteVeOfVh(""));

    history.push("/filter/" + type);
  };

  const handleFilterMax = () => {
    apiClient()
      .get(`/annonce/getpricemax`)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: FILTER_MAX, payload: parseInt(response?.data?.data) });
        }
      });
  };

  useEffect(() => {
    setCurrentPage(parseInt(pagenumber) || 1);
  }, [pagenumber]);


  useEffect(() => {
    handleFilterMax();
  }, []);

  const handleSearchChange = (e) => {
    // setSearchQuery(e.target.value);
    e.preventDefault();
    dispatch({type: SEARCH_WORDS, payload:e.target.value});
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    let sortedList;
    let thelist = searchWords === "" ? liste_annonce : filtredListe;
    switch (option) {
      case "Le moins cher":
        sortedList = [...thelist].sort(
          (a, b) => a.price_day - b.price_day
        );
        break;
      case "Le mieux noté":
        sortedList = [...thelist].sort((a, b) => b.notes - a.notes);
        break;
      default:
        sortedList = thelist;
    }
    setFiltredListe(sortedList);
  };

  useEffect(() => {
    // let thelist = searchWords === "" ? liste_annonce : filtredListe;

    const filteredList = liste_annonce?.filter((item) => {
      const lowerCaseSearchQuery = searchWords.toLowerCase();
      const lowerCaseTitle = item.title.toLowerCase();
      const lowerCaseMarque = item.marque.toLowerCase();
      const lowerCaseModel = item.model.toLowerCase();

      return (
        lowerCaseTitle.includes(lowerCaseSearchQuery) ||
        lowerCaseMarque.includes(lowerCaseSearchQuery) ||
        lowerCaseModel.includes(lowerCaseSearchQuery)
      );
    });

    setFiltredListe(filteredList?.slice(
      indexOfFirstItem,
      indexOfLastItem
    ));
    
    // Recalculate pagination count when search query changes
    const totalCount = filteredList?.length || liste_annonce?.length || 0;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    setPaginationCount(pageCount);

  }, [searchWords, currentPage, liste_annonce]);

  const handleFilterTrue = (e) => {
    e.preventDefault();
    dispatch({type: FILTER_CHECK, payload: false})
     switch (type) {
        case "camion":
          if (genre_camion_sr?.length !== 0) {
            dispatch(
              getAnnonceListe3({
                type_de_vh: type,
                user_id: user?.user?.id,
                genre_camion: genre_camion_sr,
              })
            );
          } else {
            dispatch(
              getAnnonceListe3({
                type_de_vh: type,
                user_id: user?.user?.id,
              })
            );
          }
          break;
        case "bus":
          if (nbr_plc_sr?.length !== 0) {
            dispatch(
              getAnnonceListe2({
                type_de_vh: type,
                user_id: user?.user?.id,
                nbr_plc_bus: nbr_plc_sr,
              })
            );
          } else {
            dispatch(
              getAnnonceListe2({
                type_de_vh: type,
                user_id: user?.user?.id,
              })
            );
          }
          break;
        case "voiture":
          dispatch(
            getAnnonceListe({ type_de_vh: type, user_id: user?.user?.id })
          );
          break;
        case "moto":
          dispatch(
            getAnnonceListe4({ type_de_vh: type, user_id: user?.user?.id })
          );
          break;

        default:
          break;
      }
  }

  return (
    <>
      {loading == 1 && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
      <div className="show-all-container">
        <button className="btn filter-btn" onClick={(e) => handleNav(e)}>
          Filtrer
          <UilFilter size="20" color="white" />
        </button>
        <div class="input-group">
          <span class="input-group-prepend">
            <div class=" search-icon bg-white">
              <UilSearch color="grey" />
            </div>
          </span>
          <input
            className="form-control py-2 border-left-0 border"
            type="search"
            value={searchWords}
            placeholder="Rechercher une annonce ...."
            id="example-search-input"
            onChange={handleSearchChange}
            autoComplete="off"
          />
        </div>
        <div className="filter-item" style={{justifyContent: filter_check_ch ? "space-between" : "flex-end"}}>
          {filter_check_ch && <button className="remove-filter" onClick={(e) => handleFilterTrue(e)}>Supprimer le filtrage</button>}
          <DropdownButton id="dropdown-basic-button" title="Trier Par">
            <Dropdown.Item onClick={() => handleSortChange("Le moins cher")}>
              Le moins cher
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSortChange("Le mieux noté")}>
              Le mieux noté
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="show-items-grid">
          {filtredListe?.map((item, index) => (
            <ShowVheic vhei={item} key={index} />
          ))}
        </div>
        {filtredListe?.length === 0 && (
          <div className="sorry-div">
            <SearchIcon />
            <span>Aucun résultat trouvé</span>
            <span>
              Désolé, nous n'avons pas trouvé ce que vous cherchez, veuillez
              réessayer...
            </span>
          </div>
        )}

        <div
          className="pagination_section"
          style={{ paddingBottom: "80px", marginTop: "-24px" }}
        >
          <Stack spacing={2} mt={2}>
            <Pagination
              count={paginationCount}
              color="secondary"
              page={currentPage}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </>
  );
}
