import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  UilArrowLeft,
  UilPlus,
  UilAngleRight,
  UilShoppingBag,
  UilMegaphone,
  UilUserSquare,
  UilGift,
  UilLockAlt,
  UilShield,
  UilLifeRing,
  UilWrench,
} from "@iconscout/react-unicons";
import { UilBars, UilPhone } from "@iconscout/react-unicons";
import { Mark, Phone, UserPng, Wathsapp } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { IS_SIDE_BAR, SET_USER } from "../../constants/actions";
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";
import { getListDep, logoutFunct } from "../../actions/user";
import { PICS_BASE_URL } from "../config/keys";
import {
  getAnneeOfVh,
  getBoiteVeOfVh,
  getChauffeurOfVh,
  getDescriptionOfVh,
  getKmeOfVh,
  getMarqueOfVh,
  getModelOfVh,
  getNbrPlaceOfVh,
  getPicsExterOfVh,
  getPicsInterOfVh,
  getPourcentageOfVh,
  getPriceOfVh,
  getRemiseOfVh,
  getTitleOfVh,
  getTypeOfVh,
} from "../../actions/annonce";
import CallIcon from "@mui/icons-material/Call";
import { apiClient } from "../../actions/api";

export default function SideBar(props) {
  const [state, setState] = React.useState({
    top: false,
    right: false,
    bottom: false,
    right: false,
  });
  const [profilePictureUrl, setProfilePicture] = useState()
  const { sidebar } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [typeShare, setTypeShare] = useState(localStorage.getItem('typepush'))
  const [isitshow, setIsitshow] = useState(user?.user?.is_it_dep)
  const phoneNumberKleev = "+2250712530418"
  const handledepp = (e) => {
    e.preventDefault();
    history.push("/getdep");

    // (position) => {
    //   const { latitude, longitude } = position.coords;
    //   dispatch(getListDep({lat: latitude, lng : longitude}))
    //   history.push("/getdep");
    // },
    // (error) => {
    //   console.error("Error getting user's location:", error.message);
    //   window.alert("Please enable location services to use this feature.");
    // }

    
  };

  useEffect(() => {
    setIsitshow(user?.user?.is_it_dep)
  }, [user])

  const TabsData = [
    {
      dest: "/mesoffres",
      icon: <UilPhone color="grey" size="35" />,
      text: "Offres reçues",
    },
    {
      dest: "/myreservations",
      icon: <UilShoppingBag color="grey" size="35" />,
      text: "Mes réservations",
    },
    {
      dest: "/mypubs",
      icon: <UilMegaphone color="grey" size="35" />,
      text: "Mes annonces publiées",
    },
    {
      dest: "/myinfos",
      icon: <UilUserSquare color="grey" size="35" />,
      text: "Mes informations",
    },
    {
      dest: "/privacy",
      icon: <UilShield color="grey" size="35" />,
      text: "RGPD",
    },
    // {
    //   dest: "#",
    //   icon: <UilGift color="grey" size="35" />,
    //   text: "Parrainer un ami",
    // },
  ];
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({
      ...state,
      [anchor]: open,
    });
  };
  const closeSide = (e) => {
    e.preventDefault();
    dispatch({ type: IS_SIDE_BAR, payload: false });
  };
  const handlepub = (e) => {
    e.preventDefault();
    dispatch(getTypeOfVh(""));
    dispatch(getModelOfVh(""));
    dispatch(getMarqueOfVh(""));
    dispatch(getAnneeOfVh(""));
    dispatch(getBoiteVeOfVh(""));
    dispatch(getKmeOfVh(""));
    dispatch(getNbrPlaceOfVh(""));
    dispatch(getChauffeurOfVh(0));
    dispatch(getPriceOfVh(""));
    dispatch(getRemiseOfVh(""));
    dispatch(getPourcentageOfVh(""));
    dispatch(getTitleOfVh(""));
    dispatch(getDescriptionOfVh(""));
    dispatch(getPicsExterOfVh([]));
    dispatch(getPicsInterOfVh([]));
    history.push("/newpub/1");
  };
  const handleNav = (dest) => {
    dispatch({ type: IS_SIDE_BAR, payload: false });
    history.push(dest);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutFunct());
    history.push("/");
  };
  const handleCallButtonClick = () => {
    const phoneLink = `tel:${phoneNumberKleev}`;
    window.location.href = phoneLink;
  };

  const handleWhatsAppButtonClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumberKleev}`;

    window.location.href = whatsappLink;
  };


  useEffect(() => {
    setProfilePicture(user?.user?.profile_picture
      ? `${PICS_BASE_URL}/${user?.user?.profile_picture}`.replace(
          "//images",
          "/images"
        )
      : UserPng)
  }, [user])

  const handleOverlayClick = (event) => {
    const sidebarContent = document.querySelector(".side-container");

    if (sidebarContent && sidebarContent.contains(event.target)) {
      return;
    }
    dispatch({ type: IS_SIDE_BAR, payload: false });
  };

  const showSideBar = (e) => {
    e.preventDefault();
    dispatch({ type: IS_SIDE_BAR, payload: true });
  };

  // useEffect(() => {
  //   apiClient()
  //   .get(`/user-exact/${user?.user?.id}`)
  //   .then((res) => {
  //     if(res?.data?.success){
  //       dispatch({ type: SET_USER, payload: res.data.user });
  //     }
  //   })
  // }, [])

  return (
    <>
      {props.btn && (
        <button
          className="btn-side btn"
          // onClick={toggleDrawer("right", true)}
          onClick={(e) => showSideBar(e)}
        >
          <UilBars />
        </button>
      )}
      <div className="frag-container">
        <SwipeableDrawer
          anchor={"right"}
          open={sidebar}
          onClose={toggleDrawer("right", false)}
          className="drawer-body"
          onOpen={toggleDrawer("right", true)}
          onClick={handleOverlayClick} // Close sidebar when clicking outside
        >
          <div className="side-container">
            <div className="side-item side-head">
              <UilArrowLeft
                color="#ffff"
                size="30"
                onClick={(e) => closeSide(e)}
              />
              <span>Menu</span>
            </div>
            <div className="side-item side-contact">
              <img onClick={handleWhatsAppButtonClick} src={Wathsapp} alt="" />
              <CallIcon onClick={handleCallButtonClick} />
            </div>
            <div className="side-item side-profile">
              <div className="avatar-sec">
                <img src={profilePictureUrl} alt="user_picture" />

                <span>{user?.user?.firstName}</span>
              </div>
              <button className="btn new-pub-btn" onClick={(e) => handlepub(e)}>
                <UilPlus id="plus" size="20" />
                &nbsp; &nbsp; &nbsp;Publier une annonce
              </button>
            </div>

            {TabsData.map((item) => {
              return (
                <div
                  className="side-item nav-item"
                  onClick={() => handleNav(item.dest)}
                >
                  {item.icon}
                  <span>{item.text}</span>
                  <UilAngleRight color="white" />
                </div>
              );
            })}
            {isitshow === 1 && (
              <div
                className="side-item nav-item"
                onClick={() => handleNav("/profiledepp")}
              >
                <UilWrench color="grey" size="35" />
                <span>{"Espace dépanneur"}</span>
                <UilAngleRight color="white" />
              </div>
            )}
            <div className="depanneur-case">
              <button className="btn" onClick={(e) => handledepp(e)}>
                Trouver un dépanneur
              </button>
            </div>

            <span onClick={(e) => handleLogout(e)}>Se déconnecter</span>
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
}
