import { GENRE_CAMION, IS_MESSAGE, NBR_PLACE_BUS, TYPE_CHOICE } from "../constants/actions"

export const addtoastmessage = (message) => dispatch => {
    dispatch({type: IS_MESSAGE, payload: message})
}

export const typevhchoice = (choice) => dispatch => {
    dispatch({type: TYPE_CHOICE, payload: choice})
}

export const genreCamion = (genre) => dispatch => {
    dispatch({type: GENRE_CAMION, payload: genre})
}

export const nbrPlaceBus = (nbr_place) => dispatch => {
    dispatch({type: NBR_PLACE_BUS, payload: nbr_place})
}

