import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Line } from "../../assets/images";
// import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import {
  CountrySelect,
  EmailInput,
  NameInput,
  NavHeader,
  OtpInput,
  PasswordInput,
} from "../../components";
// trying another compo for phone picker
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// end of import
import { useHistory } from "react-router-dom";
import {
  COUNTRY_USER,
  NOM_USER,
  PHONE_NUMBER,
  SMSCODEMSG,
} from "../../constants/actions";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, save_insc_country, save_insc_email, save_insc_name, save_insc_password, save_insc_phone } from "../../actions/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../actions/api";

export default function InfoCompo(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    nomUser,
    country,
    inscr_name,
    inscr_phone,
    inscr_email,
    inscr_code,
    inscr_country,
    inscr_password
  } = useSelector((state) => state.user);
  const [phoneNum, setPhoneNum] = useState(inscr_phone);
  const [emailUser, setEmailUser] = useState(inscr_email);
  const [passwordUser, setPasswordUser] = useState(inscr_password);
  const { recev_sms_code } = useSelector((state) => state.global);
  const [smsCode, setSmsCode] = useState();
  const [nameuser, setName] = useState(inscr_name);
  const [countryU, setCountry] = useState(inscr_country);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  const data = [
    {
      header: "Inscription",
      par: "Entrez votre numèro de téléphone",
      input: (
        <PhoneInput
          onChange={(phone) => setPhoneNum({ phone })}
          className="phone-picker"
          country={"ci"}
          autoFormat={false}
          value={phoneNum?.phone}
          inputProps={{
            required: true,
            autoFocus: true
          }}
        />
      ),
    },
    {
      header: "Inscription",
      par: "Veuillez entrer le code",
      input: <OtpInput setSmsCode={setSmsCode} />,
    },
    {
      header: "Inscription",
      par: "Quel est votre nom",
      input: <NameInput nameuser={nameuser} setName={setName} />,
    },
    {
      header: "Inscription",
      par: "Votre pays de résidence",
      input: <CountrySelect alreadych={countryU} selected={setCountry} />,
    },
    {
      header: "Inscription",
      par: "Entrez votre email",
      input: <EmailInput emailUser={emailUser} setEmail={setEmailUser} />,
    },
    {
      header: "Inscription",
      par: "Entrez votre mot de passe",
      input: <PasswordInput passwordUser={passwordUser} setPassword={setPasswordUser} />,
    },
  ];

  const validateEmail = (mail_user) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(mail_user);
    return(isValidEmail);
  };

  const handleSendVerfCode = (eml) => {
    // apiClient()
    //   .post("/send-verification-code", { emailUser: eml })
    //   .then((response) => {
    //     dispatch({
    //       type: SMSCODEMSG,
    //       payload: response?.data?.verification_code,
    //     });
        history.push("/name");
      // });
  };

  const handleNavigation = (e) => {
    e.preventDefault();
    switch (props?.comp) {
      case "0":
        dispatch(save_insc_phone(phoneNum));
        if (phoneNum?.phone) {
          apiClient()
            .post("/check-phone", { phoneNumber: phoneNum?.phone })
            .then((response) => {
              if(response?.data?.exists){
                toast.error("ce numéro de téléphone existe déjà");
                // setError("ce numéro de téléphone existe déjà");
              } else {
                history.push("/email");
              }
            });
        } else {
          setError("Numéro de téléphone invalide");
        }
        break;
      case "1":
        if (smsCode?.length !== 5) {
          setError("Code pas complet");
        } else {
          if (recev_sms_code != smsCode) {
            setError("SMS code pas correct !");
          } else {
            history.push("/name");
          }
        }
        break;
      case "2":
        if (nameuser && nameuser.trim() !== "") {
          // dispatch({ type: NOM_USER, payload: nameuser });
          dispatch(save_insc_name(nameuser));
          history.push("/password");
        } else {
          setError("Nom invalide");
        }
        break;
      case "3":
        if (countryU && countryU.trim() !== "") {
          dispatch(save_insc_country(countryU));
          dispatch(
            registerUser({
              phoneNumber: inscr_phone?.phone,
              name: inscr_name,
              country: countryU,
              email: inscr_email,
              password: passwordUser
            })
          )
            .then(() => {
              dispatch(save_insc_phone(''));
              dispatch(save_insc_name(''));
              dispatch(save_insc_email(''));
              dispatch(save_insc_country(''));
              dispatch(save_insc_password(''));
              history.push("/welcome");
            })
            .catch((error) => {
              setError(
                error && "Une erreur s'est produite lors de l'inscription"
              );
            });
        } else {
          setError("Pays invalide");
        }

        break;
        case "4":
          dispatch(save_insc_email(emailUser));
          if (validateEmail(emailUser)) {
            apiClient()
              .post("/check-email", { email: emailUser})
              .then((response) => {
                if(response?.data?.exists){
                  toast.error("cet email existe déjà");
                } else {
                  handleSendVerfCode(emailUser)
                }
              });
          } else {
            setError("email invalide");
          }
        break;
        case "5":
          dispatch(save_insc_password(passwordUser));
          if (passwordUser) {
            history.push("/country");
          } else {
            setError("email invalide");
          }
        break;
      default:
        break;
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // const phoneRegex = /^\d{10}$/;
    // return phoneRegex.test(phoneNumber);
  };

  return (
    <div className=" phone-container">
      <NavHeader />
      <ToastContainer />
      <div className="phone-row">
        <div className="left-side">
          <h3>{data[props?.comp].header}</h3>
          <img src={Line} />
        </div>
        <div className="right-side">
          <div className="info-case">
            <p>{data[props?.comp].par}</p>
            {data[props?.comp].input}
          </div>
          <button
            type="button"
            className="btn send-btn"
            onClick={(e) => {
              handleNavigation(e);
            }}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
}
