import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./styles.scss";
import { useHistory } from "react-router-dom";
export default function ReservStepper(props) {
  const history = useHistory();
  // const handleclick = (index)=>{
  //   history.push('/reservstatus/'+index) ;
  // }
  const steps = [
    "En cours de validation",
    "En attente de paiement",
    "Remise de clés en cours....",
    "Terminée",
  ];
  return (
    <Box className="stepp-box" sx={{ width: "100%" }}>
      <Stepper activeStep={props.step} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            {" "}
            {/* onClick={()=>handleclick(index+1)} */}
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
