import React from 'react'
import './styles.scss'

export default function EmailInput({setEmail, emailUser}) {
  return (
  <input
  type='text' 
  className='name-input'
  value={emailUser}
  onChange={(e) => setEmail(e.target.value)}
/>        
  )
}
