import React from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import FirstUpdatePage from "../firstupdatepage";
import SecondUpdatePage from "../secondupdatepage";
import ThirdUpdatePage from "../thirdupdatepage";

export default function UpdatePubPage() {
  const { pubstep } = useSelector((state) => state.global);
  const { spec_annonce } = useSelector((state) => state.annonce);

  return (
    <div className="new-pub-container">
        {pubstep === 1 && <FirstUpdatePage data_annonce={spec_annonce} />}
        {pubstep === 2 && <SecondUpdatePage data_annonce={spec_annonce} />}
        {pubstep === 3 && <ThirdUpdatePage data_annonce={spec_annonce} />}
    </div>
    )
  ;
}
