import React from "react";
import "./styles.scss";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer, Header } from "..";

const Component = ({
  component: Component,
  protect,
  navheader,
  parts,
  pub,
  mobile,
  headtext,
  path,
  hadno,
  special,
  updatedpart,
  justheader,
  ...rest
}) => {
  const { user } = useSelector((state) => state.user);

  const RenderComponent = (props) => {
    return (
      <div
        style={{ display: mobile && "flex", flexDirection: mobile && "column" }}
      >
        {parts && <Header justheader={justheader} updatepub={updatedpart && true} />}
        {mobile && <Header justheader={justheader} updatepub={updatedpart && true} pub={pub} special={special} hadno={hadno} text={headtext} nav />}
        <Component {...props} />
        {mobile && <Footer />}
        {parts && <Footer />}
      </div>
    );
  };

  return (
    // <Route
    //   {...rest}
    //   render={(props) =>
    //     protect ? (
    //       user?.user_id ? (
    //         <RenderComponent {...props} />
    //       ) : (
    //         <Redirect to="/login" />
    //       )
    //     ) : (
    //       <RenderComponent {...props} />
    //     )
    //   }
    // />
    <Route
      {...rest}
      render={(props) =>
          (path == "/" || path == "auth") && user?.user?.id ? (
            <Redirect to="/choosepage" />
          ) : (
            <RenderComponent {...props} />
          )
      }
    />
  );
};

export default Component;
