import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";

export default function ReservationStripe({resId, remise, prix}) {
  const history = useHistory();
  const handlenav = (e) => {
    e.preventDefault();
    history.push("/reservation/"+resId);
  };

  return (
    <div className="reservation-stripe">
      <div className="tarif-div">
        <span>{prix} Fcfa / jour</span>
        <span>{remise != 0 ? 'Réduction à partir de '+remise+' jours' : 'Pas de réduction'}</span>
      </div>
      <button className="btn Reserver-tbn" onClick={(e) => handlenav(e)}>
        Réserver
      </button>
    </div>
  );
}
