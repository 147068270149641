import React from "react";
import "./styles.scss";
import { UilAngleRightB } from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import { AC_REF_OFFRE_SPEC } from "../../constants/actions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PICS_BASE_URL } from "../config/keys";

export default function OffreComp({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handlenav = (e, item) => {
    e.preventDefault();
    if(item?.status_suivi == -1){
      // something here maybe
    } else {
      dispatch({type: AC_REF_OFFRE_SPEC, payload: item})
      history.push("/showoffre/"+item?.id);
    }
    
  };

  const getEtat = (eta) => {
    switch (eta) {
      case 1:
        return "En cours de validation";
      case 2:
        return "En attente de paiement";
      case 3:
        return "Remise de clés en cours....";
      case 4:
        return "Terminée";
      case -1:
        return "Offre refusée !";
      default:
        return "En cours de validation";
    }
   
  };
  const picsExterArray = JSON.parse(data?.annonce?.pics_exter || "[]");

  return (
    <>
    <ToastContainer />
    <div className="offre-comp" style={{background: data?.status_suivi == -1 && '#000'}} onClick={(e) => handlenav(e, data)}>
      <div
        className="img-div"
        style={{
          backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`,
        }}
      ></div>
      <div className="state-div">
        <span style={{color: data?.status_suivi == -1 && '#fff'}}>{data?.annonce?.title}</span>
        <span>{getEtat(data?.status_suivi)}</span>
      </div>
      <UilAngleRightB className="angle" size="33" />
    </div>
    </>
  );
}
