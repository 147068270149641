import React, { useEffect } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { AC_REF_OFFRE_SPEC, DECISION, IS_ACCEPT_DRAWER } from "../../constants/actions";
import { updateReservation } from "../../actions/reservation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PICS_BASE_URL } from "../../components/config/keys";
import { apiClient } from "../../actions/api";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function ShowOffrePage() {
  const dispatch = useDispatch();
  const { idReservation } = useParams();
  const handleDecision = (e, decision) => {
    e.preventDefault();
    dispatch({ type: IS_ACCEPT_DRAWER, payload: true });
    dispatch({ type: DECISION, payload: decision });
  };
  const history = useHistory();

  const { ac_ref_off_sp } = useSelector((state) => state.reservation);
  
  const getSpecRes = () => {
    apiClient().get(`/reservation/getspec/${idReservation}`)
    .then((res) => {
      if(res?.data?.success){
        dispatch({type: AC_REF_OFFRE_SPEC, payload: res?.data?.reservation})
      }
    })
  }

  useEffect(() => {
    getSpecRes();

    const intervalId = setInterval(() => {
      getSpecRes();
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const picsExterArray = JSON.parse(ac_ref_off_sp?.annonce?.pics_exter || "[]");

  const calculateDuree = (startDate, endDate) => {
    const diffInTime = new Date(endDate) - new Date(startDate);
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return diffInDays;
  };

  const handleTerminer = (e) => {
    e.preventDefault();
    dispatch(updateReservation(ac_ref_off_sp?.id, {'status_suivi': 4}, history));
  }

  return (
    <div className="show-offre-container">
      <span>{formatDate(ac_ref_off_sp?.created_at)}</span>
      <div className="desktop-top">
        <div className="offre-head">
          <div
            className="img-div"
            style={{
              backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`,
            }}
          ></div>
          <div className="infos-div">
            <span>{ac_ref_off_sp?.annonce?.title}</span>
            <span>{ac_ref_off_sp?.annonce?.price_day} Fcfa / jour</span>
          </div>
        </div>
        <div className="total-case">
          <p>
            Durée de réservation{" "}
            <span>
              ({" "}
              {calculateDuree(
                ac_ref_off_sp?.date_debut,
                ac_ref_off_sp?.date_fin
              )}{" "}
              jours )
            </span>
          </p>
          <p>
            Du {formatDate(ac_ref_off_sp?.date_debut)} Au{" "}
            {formatDate(ac_ref_off_sp?.date_fin)}
          </p>
          <div className="price-div">
            <span>Montant total :</span>
            <span>{ac_ref_off_sp?.prix} Fcfa</span>
          </div>
        </div>
        {(ac_ref_off_sp?.status_suivi === 0 ||
          ac_ref_off_sp?.status_suivi === 1) && (
          <div className="btn-div">
            <button className="btn" onClick={(e) => handleDecision(e, true)}>
              Accepter
            </button>
            <button className="btn" onClick={(e) => handleDecision(e, false)}>
              Refuser
            </button>
          </div>
        )}
        {ac_ref_off_sp?.status_suivi === 2 && (
          <span style={{fontSize: '2rem', fontWeight: 600, margin: '30px auto 0'}}>En attente de paiement</span>
        )}
        {ac_ref_off_sp?.status_suivi === 3 && (
          <>
            <span style={{fontSize: '2rem', fontWeight: 600, margin: '15px auto 0'}}>Remise de clés en cours</span>
            <span style={{fontSize: '1.5rem', fontWeight: 500, margin: '5px auto 0'}}>mettre terminée : </span>
            <button className="btnterm" onClick={(e) => handleTerminer(e)}>
              Terminer
            </button>
          </>
        )}
        {ac_ref_off_sp?.status_suivi === 4 && (
          <span style={{fontSize: '2rem', fontWeight: 600, margin: '30px auto 0'}}>Terminée</span>
        )}
      </div>
    </div>
  );
}
