import React from "react";
import "./styles.scss";
import Select from "react-select";

export default function FilterComp(props) {
  const selectedvh = props?.selected && props?.options.filter((item) => item.value == props?.selected);
  const handleChange = (event) => {
    props.vhei(event.value);
  };
  return (
    <Select
      placeholder=".........."
      className="filter-select"
      onChange={(e) => {
        handleChange(e);
      }}
      options={props?.options}
      defaultValue={selectedvh}
      isSearchable={false}
    ></Select>
  );
}
