import React from 'react'
import './styles.scss'

export default function PasswordInput({setPassword, passwordUser}) {
  return (
  <input
  type='password' 
  className='name-input'
  value={passwordUser}
  onChange={(e) => setPassword(e.target.value)}
/>        
  )
}
