import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ["user", "annonce"]
};
const pReducer = persistReducer(persistConfig, rootReducer);
// const middleware = applyMiddleware(thunk, logger);
let middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}
// const store = createStore(pReducer, middleware);
const store = createStore(
    pReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  
const persistor = persistStore(store);
export { persistor, store };