//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";
export const IS_SUBMITED  ="IS_SUBMITED";
export const IS_MESSAGE = "IS_MESSAGE";
export const IS_ERROR  ="IS_ERROR";
//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "LOGOUT_USER"
//Vheicule 
export const ADD_VHEICULE  = "ADD_VHEICULE" ; 
export const EDIT_VHEICULE  = "EDIT_VHEICULE" ; 
export const IS_DRAWER = "IS_DRAWER"
export const IS_DRAWER_PAY = "IS_DRAWER_PAY"
export const IS_RES_DRAWER = "IS_RES_DRAWE"
export const IS_ACCEPT_DRAWER = "IS_ACCEPT_DRAWER"
export const IS_PUB_STEP = "IS_PUB_STEP"
export const IS_SIDE_BAR = "IS_SIDE_BAR"
export const DECISION = "DECISION"
export const IS_POS_DRAWER="IS_POS_DRAWE"
export const IS_RATE_DRAWER="IS_RATE_DRAWER"
export const DATA_DEP_NOTES="DATA_DEP_NOTES"
export const DATA_DEP_ID="DATA_DEP_ID"
export const IS_MARQUE_DRAWER="IS_MARQUE_DRAWER"
export const VH_MARQUE = "VH_MARQUE" ; 
// export const 

// for annonces
export const LIST_ANNONCES = "LIST_ANNONCES"
export const TYPE_VH = "TYPE_VH"
export const MODEL_VH = "MODEL_VH"
export const MARQUE_VH = "MARQUE_VH"
export const ANNEE_VH = "ANNEE_VH"  
export const BOITEVE_VH = "BOITEVE_VH"
export const KME_VH = "KME_VH"
export const NBR_PLACE_VH = "NBR_PLACEE_VH"
export const CHAUFFEURE_VH = "CHAUFFEURE_VH"
export const PRICEE_VH = "PRICEE_VH"
export const REMISE_VH = "REMISEE_VH"
export const POURCENTE_VH = "POURCENTE_VH"
export const TITLEE_VH = "TITLEE_VH"
export const DESCRIPTIONE_VH = "DESCRIPTIONE_VH"
export const PICS_INTERE_VH = "PICS_INTERE_VH"
export const PICS_EXTERE_VH = "PICS_EXTERE_VH"

// for user
export const PHONE_NUMBER = "PHONE_NUMBER"
export const NOM_USER = "NOM_USER"
export const COUNTRY_USER = "COUNTRY_USER"

export const SPEC_ANNONCE = "SPEC_ANNONCE"
export const MES_ANNONCES = "MES_ANNONCES"
export const TYPE_CHOICE = "TYPE_CHOICE"

export const START_DATE = "START_DATE"
export const END_DATE = "END_DATE"
export const FINAL_PRICE = "FINAL_PRICE"
export const NUMBER_RES = "NUMBER_RES"
export const COUNTRY_RES = "COUNTRY_RES"
export const LIST_RES_LOCA = "LIST_RES_LOCA"
export const SPECIFIC_RESERVATION = "SPECIFIC_RESERVATION"
export const LIST_REC_OFFRES = "LIST_REC_OFFRES"
export const AC_REF_OFFRE_SPEC = "AC_REF_OFFRE_SPEC"

export const LIST_DEP = "LIST_DEP"

export const SMSCODEMSG = "SMSCODEMSG"

// for search data
export const GENRE_CAMION = "GENRE_CAMION"
export const NBR_PLACE_BUS = "NBR_PLACE_BUS"

// for reservations
export const NORMAL_PRICE = "NORMAL_PRICE"
export const RED_PRICE = "RED_PRICE"
export const RES_START_DATE = "RES_START_DATE"
export const RES_FINAL_DATE = "RES_FINAL_DATE"

// for inscription part
export const INSC_NAME = "INSC_NAME"
export const INSC_PHONE = "INSC_PHONE"
export const INSC_EMAIL = "INSC_EMAIL"
export const INSC_COUNTRY = "INSC_COUNTRY"
export const INSC_CODE = "INSC_CODE"
export const INSC_PASSWORD = "INSC_PASSWORD"

export const FILTER_MAX = "FILTER_MAX"

export const SMALL_PRICE = "SMALL_PRICE"
export const BIG_PRICE = "BIG_PRICE"

// to check if the filtrage is there
export const FILTER_CHECK = "FILTER_CHECK"

export const SEARCH_WORDS = "SEARCH_WORDS"

export const PRICE_FOR_FILTER = "PRICE_FOR_FILTER"