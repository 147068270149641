import { TOGGLE_LOADING,IS_MESSAGE,IS_SUBMITED,TYPE_CHOICE, IS_ERROR,IS_DRAWER,IS_DRAWER_PAY,IS_RES_DRAWER ,IS_PUB_STEP, IS_ACCEPT_DRAWER, DECISION, IS_SIDE_BAR,IS_RATE_DRAWER, IS_POS_DRAWER, IS_MARQUE_DRAWER, VH_MARQUE, SMSCODEMSG, GENRE_CAMION, NBR_PLACE_BUS, FILTER_CHECK, SEARCH_WORDS, PRICE_FOR_FILTER} from "../constants/actions"

const defaultState = {
    loading:false,
    issubmited:false,
    iserror:'',
    drawer:false,
    payementdrawer:false,
    reservationdrawer:false , 
    pubstep:1 ,
    acceptdrawer:false,
    decision:true,
    sidebar:false,
    ratedrawer:false,
    positiondepp:false,
    vhmarquedrawer:false,
    vhmarque:'',
    message_toast:'',
    typechoice: 'voiture',
    recev_sms_code: null,
    genre_camion_sr: [],
    nbr_plc_sr: [],
    filter_check_ch: false,
    searchWords: '',
    allprice_filter: [0, 935000]
}

export default (state = defaultState, action) => {
    const { type, payload = {} } = action
    switch (type) {
        case TOGGLE_LOADING:
            return {...state, loading:payload}
        case IS_SUBMITED:
            return {...state, issubmited:payload} 
        case IS_MESSAGE:
            return {...state, message_toast:payload} 
        case IS_ERROR:
            return {...state, iserror:payload}     
        case IS_DRAWER :
            return{...state,drawer:payload}
        case IS_DRAWER_PAY :
            return{...state,payementdrawer:payload}
        case IS_RES_DRAWER :
            return{...state,reservationdrawer:payload}
        case IS_PUB_STEP :
            return{...state,pubstep:payload}
        case IS_ACCEPT_DRAWER :
            return{...state,acceptdrawer:payload}
        case DECISION :
            return{...state,decision:payload}
        case IS_SIDE_BAR :
            return{...state,sidebar:payload}
        case IS_RATE_DRAWER:
            return{...state,ratedrawer:payload}
        case IS_POS_DRAWER : 
              return{...state,positiondepp:payload}
        case  IS_MARQUE_DRAWER :
            return{...state,vhmarquedrawer:payload}
        case VH_MARQUE : 
            return{...state,vhmarque:payload}
        case TYPE_CHOICE:
            return{...state,typechoice:payload}
        case SMSCODEMSG:
            return{...state,recev_sms_code:payload}
        case GENRE_CAMION:
            return{...state,genre_camion_sr:payload}
        case NBR_PLACE_BUS:
            return{...state,nbr_plc_sr:payload}
        case FILTER_CHECK:
            return{...state,filter_check_ch:payload}
        case SEARCH_WORDS:
            return{...state,searchWords:payload}
        case PRICE_FOR_FILTER:
            return {...state,allprice_filter:payload}
        default:
            return state
    }
}
