import React, { useEffect, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import "./styles.scss";
import { Money, Mtn, OrangePay, SendWaves, Visa, AllPayWays } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { IS_DRAWER_PAY, IS_RES_DRAWER } from "../../constants/actions";
import { updateReservation } from "../../actions/reservation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function PayementDrawer() {
  const dispatch = useDispatch();
  const { spec_res_loc } = useSelector((state) => state.reservation);
  const [paymentMode, setPaymentMode] = useState("");
  const [listenerpayment, setListenerpayment] = useState(false);
  const [transaction_id, setTransaction_id] = useState(0);
  const [channels, setChannels] = useState(false);
  useEffect(() => {
    setTransaction_id(Math.floor(Math.random() * 100000000).toString());
  }, []);
  const history = useHistory();
  const handleNav = (e) => {
    e.preventDefault();
    // dispatch(updateReservation(spec_res_loc?.id, {'mode_pay' : paymentMode, 'status_suivi' : 3}, history));
    dispatch({ type: IS_DRAWER_PAY, payload: false });
    dispatch({ type: IS_RES_DRAWER, payload: true });
  };

  useEffect(() => {
    if (!listenerpayment) return;

    const interval = setInterval(() => {
      let state = document.getElementById("statut_paiement");

      if (state && state.value === "1") {
        setListenerpayment(false);
        // savecommandePay()
      } else if (state && state.value === "-1") {
        setListenerpayment(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [listenerpayment]);

  const lanshpayment = (e) => {
    e.preventDefault();

    if (paymentMode !== "card") {
      dispatch(
        updateReservation(
          spec_res_loc?.id,
          { mode_pay: paymentMode, status_suivi: 2 }, // 3 waiting until user pay in bureau then admin change it
          history
        )
      );
      dispatch({ type: IS_DRAWER_PAY, payload: false });
      dispatch({ type: IS_RES_DRAWER, payload: true });
    } else {
      window.checkout();
      let state = document.getElementById("statut_paiement");

      if (state && state.value === "1") {
        setListenerpayment(false);
        savecommandePay();
      } else if (state && state.value === "-1") {
        setListenerpayment(false);
      }

      setListenerpayment(true);
    }
  };

  const savecommandePay = () => {
    dispatch(
      updateReservation(
        spec_res_loc?.id,
        {
          mode_pay: paymentMode,
          status_suivi: 3,
          transaction_id: transaction_id,
        },
        history
      )
    );
    // add payer
    dispatch({ type: IS_DRAWER_PAY, payload: false });
    dispatch({ type: IS_RES_DRAWER, payload: true });
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    dispatch({ type: IS_DRAWER_PAY, payload: false });
  }

  return (
    <SwipeableDrawer className="pay-drawer" open={true}>
      <ArrowBackIosIcon className="arrowBackPay" onClick={(e) => handleClickBack(e)} />
      <div className="payement-container">  
        <h3>Mode de paiement</h3>
        <div className="pay-type">
          <div className="card-types">
            <p id="cards-par">Je paie en ligne</p>
            <div className="cards">
              <img style={{height: '3.6rem'}} src={AllPayWays} alt="card" />
              {/* <img src={OrangePay} alt="card" />
              <img src={Money} alt="card" />
              <img src={Mtn} alt="card" />
              <img src={SendWaves} alt="card" /> */}
            </div>
          </div>
          <input
            type="radio"
            name="paymentMode"
            value="card"
            checked={paymentMode === "card"}
            onChange={() => setPaymentMode("card")}
          />
        </div>
        <div className="pay-type">
          <div className="office-div">
            <p>Je paie dans un bureau Kleever</p>
            <span>
              Pour les paiements cash, veuillez vous rendre dans nos locaux .
            </span>
          </div>
          <input
            type="radio"
            name="paymentMode"
            value="au bureau"
            checked={paymentMode === "au bureau"}
            onChange={() => setPaymentMode("au bureau")}
          />
        </div>
        <button className="btn final-btn" onClick={(e) => lanshpayment(e)}>
          Finaliser la réservation
          {/* Finaliser la Reservation */}
        </button>
        <input
          type="hidden"
          name="transaction_id"
          id="transaction_id"
          value={transaction_id}
        />
        <input type="hidden" name="amount_id" id="amount_id" value={spec_res_loc?.prix} />
        <input type="hidden" name="channels" id="channels" value={"ALL"} />
        <input
          type="hidden"
          id="statut_paiement"
          name="statut_paiement"
          value=""
        />
      </div>
    </SwipeableDrawer>
  );
}
