import React, { useEffect, useState } from "react";
import { NavHeader } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { apiClient } from "../../actions/api";
import "./style.scss";

export default function ForgetPass() {
  const [email, setEmail] = useState();
  const [showCode, setShowCode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Veulliez saisir un email");
      return false;
    } else {
      apiClient()
        .post(`/forgot-password`, { email: email })
        .then((res) => {
          if (res.data.success === true) {
            setShowCode(true)
          } else {
            const error = res.data.error;
            if (error) {
              setError(error);
            }
          }
        });
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return (
    <div className="forgot-pass-container">
      <NavHeader />
      <h3>Mot de pass oublié</h3>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="sign-in-body">
          <div className="input-part">
            <p>Votre adresse e-mail</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {showCode && <div className="input-part">
            <p className="instruction-code">
              Nous vous avons envoyé un mot de passe temporaire à votre adresse
              e-mail enregistrée pour vous connecter. Nous vous recommandons
              vivement de le changer immédiatement après vous être connecté pour
              une sécurité renforcée.
            </p>
          </div>}
        </div>
        <button className="btn cnx-btn" type="submit">
          Envoyer
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}
