export const ListeOfCountries = {
    AF: "Afghanistan",
    AX: "Åland",
    AL: "Albanie",
    DZ: "Algérie",
    AS: "Samoa américaines",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctique",
    AG: "Antigua-et-Barbuda",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Biélorussie",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BQ: "Bonaire, Saint-Eustache et Saba",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BV: "Île Bouvet",
    BR: "Brésil",
    IO: "Territoire britannique de l'océan Indien",
    BN: "Brunei",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    KY: "Îles Caïmans",
    CF: "République centrafricaine",
    TD: "Tchad",
    CL: "Chili",
    CN: "Chine",
    CX: "Île Christmas",
    CC: "Îles Cocos",
    CO: "Colombie",
    KM: "Comores",
    CG: "Congo",
    CD: "République démocratique du Congo",
    CK: "Îles Cook",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatie",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Chypre",
    CZ: "République tchèque",
    DK: "Danemark",
    DJ: "Djibouti",
    DM: "Dominique",
    DO: "République dominicaine",
    EC: "Équateur",
    EG: "Égypte",
    SV: "Salvador",
    GQ: "Guinée équatoriale",
    ER: "Érythrée",
    EE: "Estonie",
    ET: "Éthiopie",
    FK: "Falkland Islands (Malvinas)",
    FO: "Îles Féroé",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    GF: "Guyane française",
    PF: "Polynésie française",
    TF: "Terres australes françaises",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    DE: "Allemagne",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grèce",
    GL: "Groenland",
    GD: "Grenade",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernesey",
    GN: "Guinée",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    HT: "Haïti",
    HM: "Îles Heard-et-MacDonald",
    VA: "Saint-Siège (Cité du Vatican)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hongrie",
    IS: "Islande",
    IN: "Inde",
    ID: "Indonésie",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irlande",
    IM: "Île de Man",
    IL: "Israël",
    IT: "Italie",
    JM: "Jamaïque",
    JP: "Japon",
    JE: "Jersey",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Corée du Nord",
    KR: "Corée du Sud",
    KW: "Koweït",
    KG: "Kirghizistan",
    LA: "Laos",
    LV: "Lettonie",
    LB: "Liban",
    LS: "Lesotho",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie" ,
    LU: "Luxembourg" ,
    MO: "Macao" ,
    MK: "Macédoine" ,
    MG: "Madagascar" ,
    MW: "Malawi" ,
    MY: "Malaisie" ,
    MV: "Maldives" ,
    ML: "Mali" ,
    MT: "Malte" ,
    MH: "Îles Marshall" ,
    MQ: "Martinique" ,
    MR: "Mauritanie" ,
    MU: "Maurice" ,
    YT: "Mayotte" ,
    MX: "Mexique" ,
    FM: "Micronésie" ,
    MD: "Moldavie" ,
    MC: "Monaco" ,
    MN: "Mongolie" ,
    ME: "Monténégro" ,
    MS: "Montserrat" ,
    MA: "Maroc" ,
    MZ: "Mozambique" ,
    MM: "Birmanie" ,
    NA: "Namibie" ,
    NR: "Nauru" ,
    NP: "Népal" ,
    NL: "Pays-Bas" ,
    NC: "Nouvelle-Calédonie" ,
    NZ: "Nouvelle-Zélande" ,
    NI: "Nicaragua" ,
    NE: "Niger" ,
    NG: "Nigéria" ,
    NU: "Niue" ,
    NF: "Île Norfolk" ,
    MP: "Îles Mariannes du Nord" ,
    NO: "Norvège" ,
    OM: "Oman" ,
    PK: "Pakistan" ,
    PW: "Palaos" ,
    PS: "Palestine" ,
    PA: "Panama" ,
    PG: "Papouasie-Nouvelle-Guinée" ,
    PY: "Paraguay" ,
    PE: "Pérou" ,
    PH: "Philippines" ,
    PN: "Îles Pitcairn" ,
    PL: "Pologne" ,
    PT: "Portugal" ,
    PR: "Porto Rico" ,
    QA: "Qatar" ,
    RE: "Réunion" ,
    RO: "Roumanie" ,
    RU: "Russie" ,
    RW: "Rwanda" ,
    BL: "Saint-Barthélemy" ,
    SH: "Sainte-Hélène" ,
    KN: "Saint-Kitts-et-Nevis" ,
    LC: "Sainte-Lucie" ,
    MF: "Saint-Martin" ,
    PM: "Saint-Pierre-et-Miquelon" ,
    VC: "Saint-Vincent-et-les-Grenadines" ,
    WS: "Samoa" ,
    SM: "Saint-Marin" ,
    ST: "Sao Tomé-et-Principe" ,
    SA: "Arabie saoudite" ,
    SN: "Sénégal" ,
    RS: "Serbie" ,
    SC: "Seychelles" ,
    SL: "Sierra Leone" ,
    SG: "Singapour" ,
    SX: "Sint Maarten" ,
    SK: "Slovaquie" ,
    SI: "Slovénie" ,
    SB: "Îles Salomon" ,
    SO: "Somalie" ,
    ZA: "Afrique du Sud" ,
    GS: "Géorgie du Sud-et-les îles Sandwich du Sud" ,
    SS: "Soudan du Sud" ,
    ES: "Espagne" ,
    LK: "Sri Lanka" ,
    SD: "Soudan" ,
    SR: "Surinam" ,
    SJ: "Svalbard et Jan Mayen" ,
    SE: "Suède" ,
    CH: "Suisse" ,
    SY: "Syrie" ,
    TW: "Taïwan" ,
    TJ: "Tadjikistan" ,
    TZ: "Tanzanie" ,
    TH: "Thaïlande" ,
    TL: "Timor-Leste" ,
    TG: "Togo" ,
    TK: "Tokelau" ,
    TO: "Tonga" ,
    TT: "Trinité-et-Tobago" ,
    TN: "Tunisie" ,
    TR: "Turquie" ,
    TM: "Turkménistan" ,
    TC: "Îles Turques-et-Caïques" ,
    TV: "Tuvalu" ,
    UG: "Ouganda" ,
    UA: "Ukraine" ,
    AE: "Émirats arabes unis" ,
    GB: "Royaume-Uni" ,
    US: "États-Unis d'Amérique" ,
    UM: "Îles mineures éloignées des États-Unis" ,
    UY: "Uruguay" ,
    UZ: "Ouzbékistan" ,
    VU: "Vanuatu" ,
    VE: "Venezuela" ,
    VN: "Viêt Nam" ,
    VG: "Îles Vierges britanniques" ,
    VI: "Îles Vierges des États-Unis" ,
    WF: "Wallis-et-Futuna" ,
    EH: "Sahara occidental" ,
    YE: "Yémen" ,
    ZM: "Zambie" ,
    ZW: "Zimbabwe"
}  