import React from "react";
import "./styles.scss";
import FirstPubPage from "../firstpubpage";
import SecondPubPage from "../secondpubpage";
import ThirdPubPage from "../thirdpubpage";
import { useSelector } from "react-redux";

export default function NewPubPage() {
  const { pubstep } = useSelector((state) => state.global);

  return (
    <div className="new-pub-container">
        {pubstep === 1 && <FirstPubPage />}
        {pubstep === 2 && <SecondPubPage />}
        {pubstep === 3 && <ThirdPubPage />}
    </div>
    )
  ;
}
