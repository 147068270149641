import React, { useState } from "react";
import "./styles.scss";
import { MercedesTruck, Star, mercedes, nissan } from "../../assets/images";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { getSpecificAnnonce } from "../../actions/annonce";
import { useDispatch } from "react-redux";
import { PICS_BASE_URL } from "../config/keys";

export default function ShowVheicComp({ vhei, key }) {
  const history = useHistory();
  const [index, setIndex] = useState(1);
  const dispatch = useDispatch();
  const carousel = [{ data: "1" }, { data: "2" }];
  
  const handlecarousel = (type) => {
    if (type == "-") {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };

  // const picsExterArray = JSON.parse(vhei?.pics_exter || "[]");
  // const picsInterArray = JSON.parse(vhei?.pics_inter || "[]");
  // const combinedArray = picsExterArray.concat(picsInterArray);
  const picsExterArray = JSON.parse(vhei?.pics_exter || "[]") || [];
  const picsInterArray = JSON.parse(vhei?.pics_inter || "[]") || [];

  const combinedArray = picsExterArray.concat(picsInterArray);

  const handleChooseAnnonce = () => {
    dispatch(getSpecificAnnonce(vhei))
    history.push(`/vhdetails/${vhei?.id}`)
  }

  return (
    <div
      className="show-vheic-container"
      onClick={() => handleChooseAnnonce()}
      key={key}
    >
      <Carousel>
        {combinedArray?.map((item) => {
          return (
            <Carousel.Item
              style={{
                backgroundImage: `url('${PICS_BASE_URL}/${item}')`,
              }}
            >
              {vhei?.remise != 0 && <span>rèduction possible</span>}
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className="infos-div">
        <div className="speci-side">
          <div className="top-speci">
            <span>Marque de véhicule</span>
            <span>{vhei?.marque}</span>
          </div>
          <div className="rate-div">
            <span>Notes et avis</span>
            <div className="rate-icon">
              <img src={Star} alt="" />
              <span>{vhei?.notes !== null ? Number(vhei?.notes)?.toFixed(0) : 0}</span>
            </div>
          </div>
        </div>
        <div className="price-side">
          <span>Tarif/ jours</span>
          <span>{vhei?.price_day} Frs</span>
        </div>
      </div>
    </div>
  );
}
