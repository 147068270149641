import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Calendar } from "../../assets/images";
import { TotalStripe } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAnnonceById } from "../../actions/annonce";
import { useParams } from "react-router-dom";
import { saveStartDate, saveFinalPrice, saveEndDate, addResPrice, addResRedPrice, addStartDateRes, addFinalDateRes } from "../../actions/reservation";
import { PICS_BASE_URL } from "../../components/config/keys";

export default function ReservationPage() {
  const { spec_annonce } = useSelector((state) => state.annonce);
  const {
    normal_price_res,
    red_price_res,
    start_date_res,
    end_date_res,
  } = useSelector((state) => state.reservation);
  const picsExterArray = JSON.parse(spec_annonce?.pics_exter || "[]");
  const [startDate, setStartDate] = useState(start_date_res == '' ? null : start_date_res);
  const [endDate, setEndDate] = useState(end_date_res == '' ? null : end_date_res);
  const [duree, setDuree] = useState(0);
  const [error, setError] = useState(null);
  const [normalPrice, setNormalPrice] = useState(normal_price_res);
  const [reduPrice, setReduPrice] = useState(red_price_res);
  const datePickerRef = useRef(null);
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const backgroundImageStyle = picsExterArray && picsExterArray.length > 0
  ? { backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')` }
  : {};

  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  useEffect(() => {
    dispatch(getAnnonceById({userId: user?.user?.id, annonceId:id}))
  }, []);

  // const handleshowPicker = () => {
  //   if (datePickerRef.current) {
  //     datePickerRef.current.click();
  //   }
  // };

  const handleshowPicker = (type) => {
    if (type === 'start') {
      startDatePickerRef.current.click();
    } else if (type === 'end') {
      endDatePickerRef.current.click();
    }
  };
  

  const history = useHistory();

  const handleNav = (e) => {
    e.preventDefault();

    if (duree == 0) {
      setError("Veuillez sélectionner une durée de réservation");
    } else {
      dispatch(saveStartDate(startDate))
      dispatch(saveEndDate(endDate))
      dispatch(saveFinalPrice(reduPrice))
      history.push("/reserveinfos");
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setError("La date de début ne peut pas être après la date de fin");
      } else {
        const diffInTime = new Date(endDate) - new Date(startDate);
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        setDuree(diffInDays);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (duree != 0) {
      let normlPrice = duree * spec_annonce?.price_day;
      let priceKleever = (duree * spec_annonce?.price_day); //* 0.05
      let finalPrice = (normlPrice)
      setNormalPrice(finalPrice);
      let reduPrice = ( finalPrice * (100 - spec_annonce?.remise)) / 100;
      if (duree >= spec_annonce?.remise) {
        setReduPrice(reduPrice);
      } else {
        setReduPrice(normlPrice);
      }
    }
  }, [duree]);

  useEffect(() => {
    dispatch(addResPrice(normalPrice))
  }, [normalPrice])

  useEffect(() => {
    dispatch(addResRedPrice(reduPrice))
  }, [reduPrice])
  
  useEffect(() => {
    dispatch(addStartDateRes(startDate))
  }, [startDate])

  useEffect(() => {
    dispatch(addFinalDateRes(endDate))
  }, [endDate])


  return (
    <div className="reservation-container">
      <ToastContainer />
      <div className="top-div">
        <div
          className="reserv-pic"
          // style={
          //   picsExterArray && picsExterArray.length > 0
          //   && {backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`}
          // }
          style={{backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`}}

        ></div>
        <div className="infos-div">
          <span>{spec_annonce?.title}</span>
          <span>{spec_annonce?.price_day} fcfa / jour</span>
          <span>
            {spec_annonce?.remise != 0
              ? "Réduction à partir de " + spec_annonce?.remise + " jours"
              : "Pas de réduction"}
          </span>
        </div>
      </div>
      <div className="middle-div">
        <div className="date-part">
          <p>Définissez la durée de réservation</p>
          <div className="select-date">
            <div className="date-div" onClick={() => handleshowPicker('start')}>
              <img src={Calendar} alt="" />
              <span>Du</span>
              <input
                type="date"
                ref={startDatePickerRef}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-div" onClick={() => handleshowPicker('end')}>
              <img src={Calendar} alt="" />
              <span>Au</span>
              <input
                ref={endDatePickerRef}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        {duree !== 0 ? (
          <div className="total-div">
            <div className="total-days">
              <p>Durée de réservation</p>
              <span>{duree == 1 ? "1 jour" : duree + " jours"}</span>
            </div>
            <TotalStripe norml_price={normalPrice} reduc_price={reduPrice} />
          </div>
        ) : (
          <div className="total-div"></div>
        )}
        <button className="btn confirme-btn" onClick={(e) => handleNav(e)}>
          Confirmer
        </button>
      </div>
    </div>
  );
}
