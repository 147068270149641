import React, { useEffect, useState } from "react";
import { FilterComp, PriceSlider, VheiInfo } from "../../components";
import { Car, Bus, Scooter, Truck } from "../../assets/images";
import "./styles.scss";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnnonceListe, getAnnonceListe2, getAnnonceListe3, getAnnonceListe4, getAnnonceListeFiltred, getBigPriceOfVh, getChauffeurOfVh, getModelOfVh, getNbrPlaceOfVh, getSmallPriceOfVh, getTypeOfVh } from "../../actions/annonce";
import { apiClient } from "../../actions/api";
import { FILTER_CHECK, FILTER_MAX } from "../../constants/actions";

export default function FilterPage() {
  const { defvh } = useParams();
  const [vhei, setVhei] = useState(defvh);
  const [smallPrice, setSmallPrice] = useState(0);
  const [chauffeur1, setChauffeur] = useState(null);
  const [vhmod, setVheimod] = useState("");
  const { user } = useSelector((state) => state.user);
  
  const dispatch = useDispatch();
  const history = useHistory();
  // const { model, boite_de_vitess, nbr_places, filter_max_value } = useSelector(
  //   (state) => state.annonce
  // );7
  const { type } = useParams();
  const {
    model,
    boite_de_vitess,
    nbr_places,
    type_de_vh,
    marque,
    chauffeur,
    big_prico,
    small_prico,
    filter_max_value
  } = useSelector((state) => state.annonce);
  let maxval = filter_max_value ? parseInt(filter_max_value) : 935000;
  const [bigPrice, setbigPrice] = useState(maxval); //filter_max_value ? filter_max_value : 45000
  const [nbrPlace, setNbrPlace] = useState();
  const { vhmarque } = useSelector((state) => state.global);
  const SelectOption = ({ img, label }) => {
    return (
      <div className="select-option">
        <img alt="select-image" src={img} />
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };

  // const handleFilterMax = () => {
  //   apiClient()
  //     .get(`/annonce/getpricemax`)
  //     .then((response) => {
  //       if (response.data.success) {
  //         dispatch({ type: FILTER_MAX, payload: (response.data.data) });
  //       }
  //     });
  // };

  // useEffect(() => {
  //   handleFilterMax();
  // }, []);

  useEffect(() => {
    dispatch(getTypeOfVh(vhei))
  }, [vhei])

  useEffect(() => {
    dispatch(getNbrPlaceOfVh(nbrPlace))
  }, [nbrPlace])

  useEffect(() => {
    dispatch(getModelOfVh(vhmod))
  }, [vhmod])

  useEffect(() => { //smallprice
    dispatch(getSmallPriceOfVh(smallPrice))
  }, [smallPrice])

  useEffect(() => { //bigprice
    dispatch(getBigPriceOfVh(bigPrice))
  }, [bigPrice])

  useEffect(() => { //chauffeur
    dispatch(getChauffeurOfVh(chauffeur1))
  }, [chauffeur1])


  const handleNav = (e) => {
    e.preventDefault();
    dispatch({type: FILTER_CHECK, payload: true})
    // let formData = {
    //   type_de_vh: vhei,
    //   model: model,
    //   marque: vhmarque,
    //   boite_de_vitess: boite_de_vitess,
    //   nbr_places: nbr_places,
    //   price_day_from: smallPrice,
    //   price_day_to: bigPrice,
    //   chauffeur: chauffeur1,
    //   user_id: user?.user?.id,
    //   genre_camion: vhmod,
    // };
    // dispatch(getAnnonceListeFiltred(formData)).then(() => {
    //   history.push("/showall/" + vhei + "/page=1");
    // }); // get the choosen type
    switch (type_de_vh) {
      case "camion":
        const filteredObject3 = Object.fromEntries(
          Object.entries({
            type_de_vh: type_de_vh,
            user_id: user?.user?.id,
            genre_camion: model,
            price_day_from: small_prico,
            price_day_to: filter_max_value, //big_prico,
            from_filter: true,
          }).filter(([key, value]) => value !== undefined)
        );
        dispatch(
          getAnnonceListe3(filteredObject3)
        );
        break;
      case "bus":
        const filteredObject1 = Object.fromEntries(
          Object.entries({
            type_de_vh: type_de_vh,
            user_id: user?.user?.id,
            nbr_plc_bus: nbr_places,
            price_day_from: small_prico,
            price_day_to: filter_max_value, //big_prico,
            from_filter: true,
          }).filter(([key, value]) => value !== undefined)
        );
        dispatch(
          getAnnonceListe2(filteredObject1)
        );
        break;
      case "voiture":
        const filteredObject = Object.fromEntries(
          Object.entries({
            type_de_vh: type,
            user_id: user?.user?.id,
            model: model,
            marque: vhmarque,
            boite_de_vitess: boite_de_vitess,
            nbr_places: nbr_places,
            price_day_from: small_prico,
            price_day_to: filter_max_value, //big_prico,
            chauffeur: chauffeur === 0 ? 2 : 1,
          }).filter(([key, value]) => value !== undefined)
        );
        dispatch(
          getAnnonceListe(filteredObject)
        );
        break;
      case "moto":
        const filteredObject2 = Object.fromEntries(
          Object.entries({
            type_de_vh: type,
            user_id: user?.user?.id,
            price_day_from: small_prico,
            price_day_to: filter_max_value, //big_prico,
          }).filter(([key, value]) => value !== undefined)
        );
        dispatch(
          getAnnonceListe4(filteredObject2)
        );
        break;

      default:
        break;
    }
      history.push("/showall/" + vhei + "/page=1");

  };

  const handleChauffeurChange = (avecChauffeur) => {
    setChauffeur((prevChauffeur) => {
      if (prevChauffeur === avecChauffeur) {
        return null; // Clicking the same button twice, set to null
      } else {
        return avecChauffeur; // Clicking a different button, set to the selected value
      }
    });
  };

  const SimpleSelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };

  const busPlaces = [
    {
      value: "eightToFifteen", //"Entre 8 - 15 places",
      label: <SimpleSelectOption value="Entre 8 - 15 places" label="Entre 8 - 15 places" />,
    },
    {
      value: "fifteenToTwenty", //"Entre 15 - 20 places",
      label: <SimpleSelectOption value="Entre 15 - 20 places" label="Entre 15 - 20 places" />,
    },
    {
      value: "twentyToThirty", //"20 places ou 30 places",
      label: <SimpleSelectOption value="20 places ou 30 places" label="20 places ou 30 places" />,
    },
    {
      value: "overThirty", //"30 places ou plus",
      label: <SimpleSelectOption value="30 places ou plus" label="30 places ou plus" />,
    },
  ];

  const camionGenre = [
    {
      value: "demenagement",
      label: (
        <SimpleSelectOption
          value="demenagement"
          label="Camion de déménagement"
        />
      ),
    },
    {
      value: "marchandises",
      label: (
        <SimpleSelectOption value="marchandises" label="Camion de marchandise" />
      ),
    },
    {
      value: "benne",
      label: <SimpleSelectOption value="benne" label="Camion benne" />,
    },
    {
      value: "grue",
      label: <SimpleSelectOption value="grue" label="Camion grue" />,
    },
    {
      value: "plateau",
      label: <SimpleSelectOption value="plateau" label="Camion plateau" />,
    },
    {
      value: "autres",
      label: <SimpleSelectOption value="autres" label="autres" />,
    },
  ];
  const handletype = () => {
    switch (vhei) {
      case "voiture":
        return <VheiInfo />;
      case "bus":
        return (
          <>
            <p>Nombre des places</p>
            {/* <Select placeholder=".........."></Select> */}
            <FilterComp selected={nbrPlace} vhei={setNbrPlace} options={busPlaces} />

          </>
        );
      case "camion":
        return (
          <>
            <div className="titles-div">
              <p>Quels types de camions voulez-vous ?</p>
              <span>possibilité d en sélectionner plusieurs </span>
            </div>
            <FilterComp
              selected={vhmod}
              vhei={setVheimod}
              options={camionGenre}
            />
          </>
        );
      default:
        break;
    }
  };
  const vhoptions = [
    {
      value: "voiture",
      label: (
        <SelectOption
          className={vhei && "disabled"}
          value="voiture"
          img={Car}
          label="Voitures"
        />
      ),
    },
    {
      value: "bus",
      label: (
        <SelectOption
          className={vhei === "Bus" && "disabled"}
          value="Bus"
          img={Bus}
          label="Bus/Car"
        />
      ),
    },
    {
      value: "moto",
      label: (
        <SelectOption
          img={Scooter}
          className={vhei === "Moto" && "disabled"}
          label="Moto"
          value="Moto"
        />
      ),
    },
    {
      value: "camion",
      label: (
        <SelectOption
          img={Truck}
          className={vhei === "Camion" && "disabled"}
          value="Camion"
          label="Camions"
        />
      ),
    },
  ];

  return (
    <div className="fiter-container">
      <p>Choisis un type de véhicule</p>
      <FilterComp selected={vhei} options={vhoptions} vhei={setVhei} />
      {handletype()}
      <p>Tarif/jours</p>
      <div className="price-range">
        <span>{smallPrice} fcfa</span>
        <span>{bigPrice} fcfa</span>
      </div>
      <PriceSlider priceFr={setSmallPrice} priceT={setbigPrice} />
      {vhei === "voiture" && (
        <>
          <p>
            Choisisser une option Location <span>( Facultatif )</span>{" "}
          </p>
          <div className="chauffeur-div">
            <button
              className={`btn ${chauffeur === 1 ? "selected" : ""}`}
              onClick={() => handleChauffeurChange(1)}
            >
              Avec Chauffeur
            </button>
            <button
              className={`btn ${chauffeur === 0 ? "selected" : ""}`}
              onClick={() => handleChauffeurChange(0)}
            >
              Sans Chauffeur
            </button>
          </div>
        </>
      )}

      <button className="btn show-btn" onClick={(e) => handleNav(e)}>
        Voir les résultats
      </button>
    </div>
  );
}
