import React, { useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { AcceptDrawer } from "..";
import { AUTHENTICATE_USER, IS_ACCEPT_DRAWER } from "../../constants/actions";
import { updateReservation } from "../../actions/reservation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { apiClient } from "../../actions/api";

export default function AcceptOverFlow({ decision }) {
  const [Acceptref, setacceptref] = useState(false);
  const { ac_ref_off_sp } = useSelector((state) => state.reservation)
  const {user} = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDecision = (decision) => {
    if (decision === true) {
      return "accepté";
    } else if(decision === 'infodelete') {
      return "supprimer votre compte";
    }else {
      return "refusé";
    }
  };
  const hidedrawer = (e, check) => {
    e.preventDefault();
    if(decision === true){
      // cas accepte
      if(check == 'yes') {
        dispatch(updateReservation(ac_ref_off_sp?.id, {'accept_refuse': 'accept', 'status_suivi': 2}, history));
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
        history.push('/mesoffres');
      } else {
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
      }
    } else if (decision === 'infodelete'){
      if(check == 'yes') {
        // delete account
        apiClient()
        .delete(`/user/${user?.user?.id}`).then((response) => {
          if(response.data.success) {
            dispatch({ type: AUTHENTICATE_USER, payload: {} });
            history.push('/');
          }
        })
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
        // history.push('/');
      } else {
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
      }
    }else {
      // cas refusé
      if(check == 'yes') {
        dispatch(updateReservation(ac_ref_off_sp?.id, {'accept_refuse': 'refuse', 'status_suivi': -1}, history));
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
        history.push('/mesoffres');
      } else {
        dispatch({ type: IS_ACCEPT_DRAWER, payload: false });
      }
    }
  };
  return (
    <SwipeableDrawer className="accept-drawer" open={true}>
      <div className="accept-drawer-container">
        <p>
          Confirmez-vous avoir{" "}
          <span id={decision == false || decision == 'infodelete' && "danger"}>
            {handleDecision(decision)}
          </span>{" "}
          {decision !== 'infodelete' && 'la réservation ?'}
        </p>
        <div className="buttons-div">
          <button className="btn " onClick={(e) => hidedrawer(e, 'yes')}>
            Oui
          </button>
          <button className="btn" onClick={(e) => hidedrawer(e, 'no')}>
            Non
          </button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
