import React, { useEffect, useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import "./styles.scss";
import MyPubsComp from "../../components/mypubscomp";
import { useDispatch, useSelector } from "react-redux";
import { getMesAnnonce } from "../../actions/annonce";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function MyPubsPage() {
  const { mes_annonces } = useSelector((state) => state.annonce);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Change as needed
  const [searchTerm, setSearchTerm] = useState("");

  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(getMesAnnonce({ userId: user?.user?.id })).then(() => {
  //     setIsLoading(false);
  //   });
  // }, [dispatch, user]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getMesAnnonce({ userId: user?.user?.id }));
      setIsLoading(false);
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [dispatch, user]);

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMesAnnonces = mes_annonces.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handleChangePage = (_, newPage) => setCurrentPage(newPage);

  const filteredMesAnnonces = currentMesAnnonces.filter((annonce) => {
    const { title, marque, model } = annonce;
    const normalizedSearchTerm = searchTerm.toLowerCase();
  
    return (
      title.toLowerCase().includes(normalizedSearchTerm) ||
      marque.toLowerCase().includes(normalizedSearchTerm) ||
      model.toLowerCase().includes(normalizedSearchTerm)
    );
  });

  return (
    <div className="my-pubs-page">
      <div className="input-group">
        <span className="input-group-prepend">
          <div className="search-icon bg-white">
            <UilSearch color="grey" />
          </div>
        </span>
        <input
          className="form-control py-2 border-left-0 border"
          type="search"
          placeholder="Rechercher une annonce"
          id="example-search-input"
          autoComplete="off"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="pubs-grid" >

      {filteredMesAnnonces.map((annonce, index) => (
          <MyPubsComp key={index} annonce={annonce} />
      ))}
      </div>

      {/* Pagination */}
      <div className="pagination_section">
      <Stack spacing={2} mt={2}>
        <Pagination
          count={Math.ceil(mes_annonces.length / itemsPerPage)}
          color="secondary"
          page={currentPage}
          onChange={handleChangePage}
        />
      </Stack>
      </div>
    </div>
  );
}
