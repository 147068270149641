import {
  LIST_ANNONCES,
  TYPE_VH,
  MODEL_VH,
  MARQUE_VH,
  ANNEE_VH,
  BOITEVE_VH,
  KME_VH,
  NBR_PLACE_VH,
  CHAUFFEURE_VH,
  PRICEE_VH,
  REMISE_VH,
  POURCENTE_VH,
  TITLEE_VH,
  DESCRIPTIONE_VH,
  PICS_INTERE_VH,
  PICS_EXTERE_VH,
  SPEC_ANNONCE,
  MES_ANNONCES,
  SMALL_PRICE,
  BIG_PRICE,
} from "../constants/actions";
import { apiClient } from "./api";

export const getAnnonceListe = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  let apiUrl = "/annonce/liste?";

  if (data?.type_de_vh) apiUrl += `type_de_vh=${data.type_de_vh}&`;
  if (data?.user_id) apiUrl += `user_id=${data.user_id}&`;
  if (data?.model) apiUrl += `model=${data.model}&`;
  if (data?.marque) apiUrl += `marque=${data.marque}&`;
  if (data?.boite_de_vitess)
    apiUrl += `boite_de_vitess=${data.boite_de_vitess}&`;
  if (data?.nbr_places) apiUrl += `nbr_places=${data.nbr_places}&`;
  if (data?.price_day_from) apiUrl += `price_day_from=${data.price_day_from}&`;
  if (data?.price_day_to) if(data?.price_day_to != "Ok") apiUrl += `price_day_to=${data.price_day_to}&`;
  if (data?.chauffeur) apiUrl += `chauffeur=${data.chauffeur?.toString()}&`;

  // Remove the trailing '&' if it exists
  apiUrl = apiUrl.replace(/&$/, "");
  return apiClient()
    .get(apiUrl)
    .then((res) => {
      if (res.data)
        dispatch({ type: LIST_ANNONCES, payload: res.data?.annonces });
    });
};

export const getAnnonceListe4 = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  let apiUrl = "/annonce/liste?";

  if (data?.type_de_vh) apiUrl += `type_de_vh=${data.type_de_vh}&`;
  if (data?.user_id) apiUrl += `user_id=${data.user_id}&`;
  if (data?.model) apiUrl += `model=${data.model}&`;
  if (data?.marque) apiUrl += `marque=${data.marque}&`;
  if (data?.boite_de_vitess)
    apiUrl += `boite_de_vitess=${data.boite_de_vitess}&`;
  if (data?.nbr_places) apiUrl += `nbr_places=${data.nbr_places}&`;
  if (data?.price_day_from) apiUrl += `price_day_from=${data.price_day_from}&`;
  if (data?.price_day_to) if(data?.price_day_to != "Ok") apiUrl += `price_day_to=${data.price_day_to}&`;
  if (data?.chauffeur) apiUrl += `chauffeur=${data.chauffeur}&`;

  // Remove the trailing '&' if it exists
  apiUrl = apiUrl.replace(/&$/, "");
  return (
    apiClient()
      .get(apiUrl)
      // .get(`/annonce/liste`, {
      //   params: data
      // })
      .then((res) => {
        if (res.data)
          dispatch({ type: LIST_ANNONCES, payload: res.data?.annonces });
      })
  );
};

export const getAnnonceListe2 = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  let apiUrl = "/annonce/liste?";

  if (data?.type_de_vh) apiUrl += `type_de_vh=${data.type_de_vh}&`;
  if (data?.user_id) apiUrl += `user_id=${data.user_id}&`;
  if (data?.model) apiUrl += `model=${data.model}&`;
  if (data?.marque) apiUrl += `marque=${data.marque}&`;
  if (data?.boite_de_vitess)
    apiUrl += `boite_de_vitess=${data.boite_de_vitess}&`;
  if (data?.nbr_plc_bus) apiUrl += `nbr_plc_bus=${data.nbr_plc_bus}&`;
  if (data?.price_day_from) apiUrl += `price_day_from=${data.price_day_from}&`;
  if (data?.price_day_to) if(data?.price_day_to != "Ok") apiUrl += `price_day_to=${data.price_day_to}&`;
  if (data?.chauffeur) apiUrl += `chauffeur=${data.chauffeur}&`;
  if (data?.from_filter) apiUrl += `from_filter=${data?.from_filter}&`;

  // Remove the trailing '&' if it exists
  apiUrl = apiUrl.replace(/&$/, "");
  return apiClient()
    .get(apiUrl)
    .then((res) => {
      if (res.data)
        dispatch({ type: LIST_ANNONCES, payload: res.data?.annonces });
    });
};

export const getAnnonceListe3 = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  let apiUrl = "/annonce/liste?";

  if (data?.type_de_vh) apiUrl += `type_de_vh=${data.type_de_vh}&`;
  if (data?.user_id) apiUrl += `user_id=${data.user_id}&`;
  if (data?.model) apiUrl += `model=${data.model}&`;
  if (data?.genre_camion) apiUrl += `genre_camion=${data.genre_camion}&`;
  if (data?.marque) apiUrl += `marque=${data.marque}&`;
  if (data?.boite_de_vitess)
    apiUrl += `boite_de_vitess=${data.boite_de_vitess}&`;
  if (data?.nbr_places) apiUrl += `nbr_places=${data.nbr_places}&`;
  if (data?.price_day_from) apiUrl += `price_day_from=${data.price_day_from}&`;
  if (data?.price_day_to) if(data?.price_day_to != "Ok") apiUrl += `price_day_to=${data.price_day_to}&`;
  if (data?.chauffeur) apiUrl += `chauffeur=${data.chauffeur}&`;
  if (data?.from_filter) apiUrl += `from_filter=${data?.from_filter}&`;

  // Remove the trailing '&' if it exists
  apiUrl = apiUrl.replace(/&$/, "");
  return apiClient()
    .get(apiUrl)
    .then((res) => {
      if (res.data)
        dispatch({ type: LIST_ANNONCES, payload: res.data?.annonces });
    });
};

export const getAnnonceListeFiltred = (params) => (dispatch) => {
  return apiClient()
    .get(
      `/annonce/liste?type_de_vh=${params?.type_de_vh}&user_id=${params?.user_id}&model=${params?.model}&marque=${params?.vhmarque}&boite_de_vitess=${params?.boite_de_vitess}&nbr_places=${params?.nbr_places}&price_day_from=${params?.smallPrice}&price_day_to=${params?.bigPrice}&chauffeur=${params?.chauffeur}&genre_camion=${params?.vhmod}`
    )
    .then((res) => {
      if (res.data)
        dispatch({ type: LIST_ANNONCES, payload: res.data?.annonces });
    });
};

// create annonce
export const createAnnonce = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  return apiClient()
    .post(`/annonce/create`, data)
    .then((res) => {
      if (res.data.sucess) {
        // maybe a toastify that showing success
        dispatch(getMesAnnonce({ userId: params?.userId }));
      }
    });
};

// update annonce
export const updateAnnonce = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  return apiClient()
    .put(`/annonce/update/${params?.annonce_id}`, data)
    .then((res) => {
      if (res.data.sucess) {
        // maybe a toastify that showing success
        dispatch(getMesAnnonce({ userId: params?.userId }));
      }
    });
};

export const getTypeOfVh = (type) => {
  return { type: TYPE_VH, payload: type };
};

export const getModelOfVh = (model) => {
  return { type: MODEL_VH, payload: model };
};

export const getMarqueOfVh = (marque) => {
  return { type: MARQUE_VH, payload: marque };
};

export const getAnneeOfVh = (annee) => {
  return { type: ANNEE_VH, payload: annee };
};

export const getBoiteVeOfVh = (boiteVe) => {
  return { type: BOITEVE_VH, payload: boiteVe };
};

export const getKmeOfVh = (kme) => {
  return { type: KME_VH, payload: kme };
};

export const getNbrPlaceOfVh = (nbrPlace) => {
  return { type: NBR_PLACE_VH, payload: nbrPlace };
};

export const getChauffeurOfVh = (chauffeur) => {
  return { type: CHAUFFEURE_VH, payload: chauffeur };
};

export const getSmallPriceOfVh = (smallprice) => {
  return { type: SMALL_PRICE, payload: smallprice };
};

export const getBigPriceOfVh = (bigprice) => {
  return { type: BIG_PRICE, payload: bigprice };
};

export const getPriceOfVh = (price) => {
  return { type: PRICEE_VH, payload: price };
};

export const getRemiseOfVh = (remise) => {
  return { type: REMISE_VH, payload: remise };
};

export const getPourcentageOfVh = (pourcentage) => {
  return { type: POURCENTE_VH, payload: pourcentage };
};

export const getTitleOfVh = (title) => {
  return { type: TITLEE_VH, payload: title };
};

export const getDescriptionOfVh = (description) => {
  return { type: DESCRIPTIONE_VH, payload: description };
};

export const getPicsInterOfVh = (picsInter) => {
  return { type: PICS_INTERE_VH, payload: picsInter };
};

export const getPicsExterOfVh = (picsExter) => {
  return { type: PICS_EXTERE_VH, payload: picsExter };
};

export const getSpecificAnnonce = (specific) => {
  return { type: SPEC_ANNONCE, payload: specific };
};

export const getMesAnnonce = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  return apiClient()
    .get(`/annonce/owner/${data?.userId}`, data)
    .then((res) => {
      if (res.data)
        dispatch({ type: MES_ANNONCES, payload: res.data?.annonces });
    });
};

export const deleteSpecAnnonce = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  return apiClient()
    .delete(`/annonce/delete/${data?.annonceId}`)
    .then((res) => {
      if (res.data) {
        dispatch(getMesAnnonce({ userId: params?.userId }));
      }
    });
};

export const getAnnonceById = (params) => (dispatch) => {
  return apiClient()
    .get(`/annonce/justone/${params?.annonceId}`)
    .then((res) => {
      if (res.data) {
        dispatch({ type: SPEC_ANNONCE, payload: res.data.annonce });
        dispatch(getMesAnnonce({ userId: params?.userId }));
      }
    });
};
