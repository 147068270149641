import React, { useState } from "react";
import "./styles.scss";
import { UilAngleRightB } from "@iconscout/react-unicons";
import { PICS_BASE_URL } from "../config/keys";

export default function VheiReservComp({ statu, data }) {
  const picsExterArray = JSON.parse(data?.annonce?.pics_exter || "[]");

  return (
    <div className="over-vhei-res">
    <div className="vhei-reserv-comp" style={{backgroundColor: data?.status_suivi == -1 && 'black'}}>
      <div
        className="img-div"
        style={{
          backgroundImage: `url('${PICS_BASE_URL}/${picsExterArray[0]}')`,
        }}
      ></div>
      <div className="state-div">
        <span style={{color: data?.status_suivi == -1 && '#fff'}} >{data?.annonce?.title}</span>
        {
          statu == 'Accepté' ?
          <span className="accepte-except1">{statu}</span>
          :
          <span>{statu}</span>
        }
      </div>
      <UilAngleRightB className="angle" color="gray" size="33" />
    </div>
          {
            statu == 'Accepté' &&
            <div className="finaliser-res">Veuillez finaliser votre réservation !</div>
          }
    </div>
  );
}
