import React, {useEffect} from "react";
import "./styles.scss";
import { useWindowSize } from "../../hooks";
import {
  AcceptDrawer,
  ChangePosComp,
  HeaderMobile,
  HeaderWeb,
  OverflowComp,
  PayementDrawer,
  PubHeader,
  RateDrawer,
  ReservationDrawer,
  VhMarqueDrarwer,
} from "../index";
import { useSelector } from "react-redux";

export default function Header(props) {
  const { drawer } = useSelector((state) => state.global);
  const { payementdrawer } = useSelector((state) => state.global);
  const { reservationdrawer } = useSelector((state) => state.global);
  const { acceptdrawer } = useSelector((state) => state.global);
  const { ratedrawer } = useSelector((state) => state.global);
  const { decision } = useSelector((state) => state.global);
  const { vhmarquedrawer } = useSelector((state) => state.global);
  const { width } = useWindowSize();


  const queryParams = new URLSearchParams(window.location.search);

  const typepush = queryParams.get('typepush');
  const token = queryParams.get('token');


  useEffect(()=>{

  
    if(token){

       localStorage.setItem('token', token);
       localStorage.setItem('typepush', typepush);
    }


 },[])


  const handleNavType = (type) => {
    if (type) {
      return <PubHeader updatepub={props?.updatepub} />;
    } else {
      return (
        <HeaderMobile
          special={props.special}
          text={props.text}
          hadno={props?.hadno}
        />
      );
    }
  };
  return (
    <>
      {vhmarquedrawer && <VhMarqueDrarwer />}
      {reservationdrawer && <ReservationDrawer />}
      {acceptdrawer && <AcceptDrawer decision={decision} />}
      {payementdrawer && <PayementDrawer />}
      {drawer && <OverflowComp />}
      {width > 700 ? (
        props.justheader ?
        <div
          className="headerkbir"
          style={{ display: "flex", flexDirection: "column", gap: "80px" }}
        >
          <HeaderWeb />
          <PubHeader updatepub={props?.updatepub} />
        </div>
        : 
        <HeaderWeb />
      ) : (
        props.nav && handleNavType(props.pub)
      )}
    </>
  );
}
