import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import CancelIcon from "@mui/icons-material/Cancel";
import {getPicsExterOfVh, getPicsInterOfVh} from "../../actions/annonce";
import { PICS_BASE_URL } from "../../components/config/keys";
import Loader from "react-js-loader";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function ThirdUpdatePage() {

  const dispatch = useDispatch();
  const {
    pics_inter,
    pics_exter,
  } = useSelector((state) => state.annonce);
  
  const [ifloading, setIfloading] = useState(0);
  const [photos, setPhotos] = useState(pics_exter);
  const [photos2, setPhotos2] = useState(pics_inter);

  const fileInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const fileInputRefs2 = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleClickfile = (c, t) => {
    if (c == 1) {
      fileInputRefs[t - 1].current.click();
    } else {
      fileInputRefs2[t - 1].current.click();
    }
  };

  const handleRemovefile = (c, index) => {
    if (c == 1) {
      let updatedPhotos = [...photos];
      updatedPhotos[index - 1] = "";
      setPhotos(updatedPhotos);
    } else {
      let updatedPhotos2 = [...photos2];
      updatedPhotos2[index - 1] = "";
      setPhotos2(updatedPhotos2);
    }
  };

  function isImage(filename) {
    // check is the image respect format
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "jpeg":
      case "bmp":
      case "png":
        return true;
      default:
        return false;
    }
  }

  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  const handleFileChange = (c, event, t) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    if (!isImage(event.target.files[0].name)) {
      alert("Veuillez sélectionner une image valide (png,jpg,jpeg,gif..)");
      return false;
    }
    formData.append("typefile", "image");

    setIfloading(1); // make loading

    apiClient()
      .post(`/upload-photos`, formData)
      .then((res) => {
        if (res.data.status === "success") {
          if (c == 1) {
            let updatedPhotos = [...photos];
            updatedPhotos[t - 1] = res.data.message;
            setPhotos(updatedPhotos);
            setIfloading(0);
          } else {
            let updatedPhotos2 = [...photos2];
            updatedPhotos2[t - 1] = res.data.message;
            setPhotos2(updatedPhotos2);
            setIfloading(0);
          }
        } else {
          setIfloading(0);
          alert("Erreur !");
        }
      });
  };


  useEffect(() => {
    dispatch(getPicsExterOfVh(photos));
  }, [photos]);

  useEffect(() => {
    dispatch(getPicsInterOfVh(photos2));
  }, [photos2]);


    return (
        <>
        {ifloading === 1 && (
          <Loader
            type="spinner-circle"
            bgColor="#000"
            color="#fff"
            size={100}
            className="absolute-center-loader"
          />
        )}
          <div className="first-img-div">
            <p>Ajouter des images montrant l'extérieur du véhicule</p>
            <span>( 4 photos maximum )</span>
            <div className="photos-grid">
              {[1, 2, 3, 4].map((index) => (
                <>
                  {photos[index - 1] ? (
                    <div style={{ position: "relative" }}>
                      <div
                        key={index}
                        className="add-photo-case case-back"
                        style={{
                          background: `url('${PICS_BASE_URL}/${
                            photos[index - 1]
                          }')`,
                        }}
                      >
                        <CancelIcon
                          className="deleteIc"
                          onClick={() => handleRemovefile(1, index)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="add-photo-case">
                      <div onClick={() => handleClickfile(1, index)}>
                        <AddCircleOutlineIcon style={{fontSize: '24px', color: '#7f11e0'}} />
                        <form style={{ padding: "0px" }}>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => handleFileChange(1, e, index)}
                            // ref={index === 1 ? fileInput1 : index === 2 ? fileInput2 : index === 3 ? fileInput3 : fileInput4}
                            ref={fileInputRefs[index - 1]}
                          />
                        </form>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="first-img-div">
            <p>Ajouter des images montrant l'intérieur du véhicule</p>
            <span>( 4 photos maximum )</span>
            <div className="photos-grid">
              {[1, 2, 3, 4].map((index) => (
                <>
                  {photos2[index - 1] ? (
                    <div style={{ position: "relative" }}>
                      <div
                        key={index}
                        className="add-photo-case case-back"
                        style={{
                          background: `url('${PICS_BASE_URL}/${
                            photos2[index - 1]
                          }')`,
                        }}
                      >
                        <CancelIcon
                          className="deleteIc"
                          onClick={() => handleRemovefile(2, index)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="add-photo-case">
                      <div onClick={() => handleClickfile(2, index)}>
                      <AddCircleOutlineIcon style={{fontSize: '24px', color: '#7f11e0'}} />
                        <form style={{ padding: "0px" }}>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => handleFileChange(2, e, index)}
                            // ref={index === 1 ? fileInput1 : index === 2 ? fileInput2 : index === 3 ? fileInput3 : fileInput4}
                            ref={fileInputRefs2[index - 1]}
                          />
                        </form>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </>
      )
}
