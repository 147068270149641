import React, { useRef, useState } from 'react'
import {SwipeableDrawer} from '@mui/material';
import {UilMapMarkerAlt, UilPhoneAlt} from '@iconscout/react-unicons'
import {useDispatch} from 'react-redux';
import {IS_RES_DRAWER} from '../../constants/actions';
import {useHistory} from 'react-router-dom';
import './styles.scss'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';

export default function ReservationDrawer() {
    const dispatch = useDispatch();
    const history = useHistory();
    const drawerRef = useRef(null);
    const [drawerOp, setDrawerOp] = useState(true);

    const handlenav = (e) => {
        e.preventDefault();
        dispatch({type: IS_RES_DRAWER, payload: false});
        history.push('/myreservations')
    }

    const handleDrawerClose = () => {
        dispatch({type: IS_RES_DRAWER, payload: false});
        setDrawerOp(false);
    }

    return (
      
      <SwipeableDrawer className='reserv-drawer' onClose={() => handleDrawerClose()} open={drawerOp} ref={drawerRef}>
            <div className='reserv-container'>
                <h3>Réservation Confirmée !</h3>
                <div className='text-div'>
                    <p className='reser-text'>
                        Vous avez choisi de payer dans nos locaux.
                        Voici l'adresse et le contact en cas de besoin :
                    </p>
                    {/* <div className='contact-div'> 
                        <span>-Un permis de Conduire (si c'est vous qui conduisez)</span>
                    </div>
                    <div className='contact-div'>
                        <span>-Un Carte d'identite </span>
                    </div> */}
                    <div className='location-call'>
                        <LocationOnIcon />
                        <span>Abidjan, Cocody, Riviera 3.</span>
                    </div>
                    <div className='location-call'>
                        <CallIcon />
                        <span>07 57 02 35 15 ou 07 55 66 33 00</span>
                    </div>
                    <br />
                    <div className='btn-div'>
                        <button className='btn' onClick={(e) => handlenav(e)}>
                            Voir ma réservation
                        </button>
                        <button className='btn' onClick={() => { dispatch({type: IS_RES_DRAWER, payload: false}) }}>
                            Retourner dans mes recherches
                        </button>
                    </div>
                </div>
            </div>
         </SwipeableDrawer>
    )
}
