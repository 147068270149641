import React, { useState } from "react";
import { UilEllipsisH } from "@iconscout/react-unicons";
import "./styles.scss";
import { deleteSpecAnnonce, getAnneeOfVh, getBoiteVeOfVh, getChauffeurOfVh, getDescriptionOfVh, getKmeOfVh, getMarqueOfVh, getModelOfVh, getNbrPlaceOfVh, getPicsExterOfVh, getPicsInterOfVh, getPourcentageOfVh, getPriceOfVh, getRemiseOfVh, getSpecificAnnonce, getTitleOfVh, getTypeOfVh } from "../../actions/annonce";
import { useDispatch, useSelector } from "react-redux";
import { PICS_BASE_URL } from "../config/keys";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { DotsMenu } from "..";

export default function MyPubsComp({ annonce }) {
  const [showdots, setShowdots] = useState(false);
  const picsExterArray = JSON.parse(annonce?.pics_exter || "[]");
  const picsInterArray = JSON.parse(annonce?.pics_inter || "[]");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDotsMenu = (e) => {
    e.preventDefault();
    setShowdots(!showdots);
  };

  const handleChangeAnnonce = (e) => {
    e.preventDefault();
    dispatch(getSpecificAnnonce(annonce));
    dispatch(getTypeOfVh(annonce?.type_de_vh));
    dispatch(getModelOfVh(annonce?.model));
    dispatch(getMarqueOfVh(annonce?.marque));
    dispatch(getAnneeOfVh(annonce?.Annee_vh));
    dispatch(getBoiteVeOfVh(annonce?.boite_de_vitess));
    dispatch(getKmeOfVh(annonce?.kilometrage));
    dispatch(getNbrPlaceOfVh(annonce?.nbr_places));
    dispatch(getChauffeurOfVh(annonce?.chauffeur));
    dispatch(getPriceOfVh(annonce?.price_day));
    dispatch(getRemiseOfVh(annonce?.remise));
    dispatch(getPourcentageOfVh(annonce?.pourcentage));
    dispatch(getTitleOfVh(annonce?.title));
    dispatch(getDescriptionOfVh(annonce?.description));
    dispatch(getPicsExterOfVh(JSON.parse(annonce?.pics_exter || "[]") || []));
    dispatch(getPicsInterOfVh(JSON.parse(annonce?.pics_inter || "[]") || []));
    setShowdots(!showdots); 
    history.push("/updatepub/1");
  };

  const handleDeleteAnnonce = (e) => {
    e.preventDefault();
    dispatch(
      deleteSpecAnnonce({ userId: user?.user?.id, annonceId: annonce?.id })
    );
    setShowdots(!showdots);
  };

  const backgroundImageUrl =
    picsExterArray != null
      ? `${PICS_BASE_URL}/${picsExterArray[0]}`
      : picsInterArray != null
      ? `${PICS_BASE_URL}/${picsInterArray[0]}`
      : `${PICS_BASE_URL}/images/no-img.jpg`;

  const getNameAcept = () => {
    switch (annonce?.accepted) {
      case null:
        return('en cours')
        break;
      case "1":
        return('acceptée')
        break;
      case "0":
        return('refusée')
        break;
    }
  }

  return (
    <div className="my-pubs-comp">
      {showdots && (
        <div className="dots-menu">
          <span onClick={(e) => handleChangeAnnonce(e)}>Modifier</span>
          <span onClick={(e) => handleDeleteAnnonce(e)}>Suprimmer</span>
        </div>
      )}
      <div
        className="img-div"
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`,
        }}
      ></div>
      <div className="text-div">
        <div className="mini-head">
          <span>{annonce?.title}</span>
          <button onClick={(e) => handleDotsMenu(e)}>
            <UilEllipsisH size="40" color="#6A01C6" />
          </button>
        </div>
        <div className="price_statut_annonce">
          <span>{annonce?.price_day} / JOURS</span>
          <span
            className={
              annonce?.accepted == null
                ? "en_cours"
                : annonce?.accepted == "1"
                ? "accepted"
                : "refused"
            }
          >
            Annonce {getNameAcept()}
          </span>
        </div>
      </div>
    </div>
  );
}
