import {
    TYPE_VH,
    MODEL_VH,
    MARQUE_VH,
    ANNEE_VH,
    BOITEVE_VH,
    KME_VH,
    NBR_PLACE_VH,
    CHAUFFEURE_VH,
    PRICEE_VH,
    REMISE_VH,
    POURCENTE_VH,
    TITLEE_VH,
    DESCRIPTIONE_VH,
    PICS_INTERE_VH,
    PICS_EXTERE_VH,
    MES_ANNONCES,
    SPEC_ANNONCE,
    LIST_ANNONCES,
    FILTER_MAX,
    BIG_PRICE,
    SMALL_PRICE
} from "../constants/actions";

const INITIAL_STATE = {
    type_de_vh: '',
    model: '',
    marque: '',
    annee_vh: '',
    boite_de_vitess: '',
    kilometrage: '',
    nbr_places: '',
    chauffeur: '',
    price_day: '',
    remise: '',
    pourcentage: '',
    title: '',
    description: '',
    pics_inter: [],
    pics_exter: [],
    userId: 1,
    liste_annonce: [],
    mes_annonces: [],
    spec_annonce: {},
    filter_min_value: '',
    filter_max_value: '',
    small_prico: '',
    big_prico: ''
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TYPE_VH:
            return {
                ...state,
                type_de_vh: action.payload
            };
        case MODEL_VH:
            return {
                ...state,
                model: action.payload
            };
        case MARQUE_VH:
            return {
                ...state,
                marque: action.payload
            };
        case ANNEE_VH:
            return {
                ...state,
                annee_vh: action.payload
            };
        case BOITEVE_VH:
            return {
                ...state,
                boite_de_vitess: action.payload
            };
        case KME_VH:
            return {
                ...state,
                kilometrage: action.payload
            };
        case NBR_PLACE_VH:
            return {
                ...state,
                nbr_places: action.payload
            };
        case CHAUFFEURE_VH:
            return {
                ...state,
                chauffeur: action.payload
            };
        case PRICEE_VH:
            return {
                ...state,
                price_day: action.payload
            };
        case REMISE_VH:
            return {
                ...state,
                remise: action.payload
            };
        case POURCENTE_VH:
            return {
                ...state,
                pourcentage: action.payload
            };
        case TITLEE_VH:
            return {
                ...state,
                title: action.payload
            };
        case DESCRIPTIONE_VH:
            return {
                ...state,
                description: action.payload
            };
        case PICS_INTERE_VH:
            return {
                ...state,
                pics_inter: action.payload
            };
        case PICS_EXTERE_VH:
            return {
                ...state,
                pics_exter: action.payload
            };
        case LIST_ANNONCES:
            return {
                ...state,
                liste_annonce: action.payload
            };
        case MES_ANNONCES:
            return {
                ...state,
                mes_annonces: action.payload
            };
        case SPEC_ANNONCE:
            return {
                ...state,
                spec_annonce: action.payload
            };
        case FILTER_MAX:
            return {
                ...state,
                filter_max_value: action.payload
            };
        case SMALL_PRICE:
            return {
                ...state,
                small_prico: action.payload
            };
        case BIG_PRICE:
            return {
                ...state,
                big_prico: action.payload
            };

        default:
            return state;
    }
}

export default reducer;
