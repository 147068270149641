import React, { useEffect, useRef, useState } from "react";
import { UilArrowLeft } from "@iconscout/react-unicons";
import { Calendar } from "../../assets/images";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { apiClient } from "../../actions/api";
import { useSelector } from "react-redux";

export default function CallHistoryPage() {
  const history = useHistory();
  const datePickerRef = useRef(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [error, setError] = useState(null);
  const [showAppels, setShowAppels] = useState(false);
  const [nbrAppels, setNbrAppels] = useState(0);
  const { user } = useSelector((state) => state.user);
  
  const handleBack = (e) => {
    e.preventDefault();
    history.push("/profiledepp");
  };
  const handlenav = (e) => {
    e.preventDefault();
    history.push("/");
  };

  useEffect(() => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setError("La date de début ne peut pas être après la date de fin");
      } else {
        apiClient()
        .get(`/call-history?userId=${user?.user?.id}&fromDate=${startDate}&toDate=${endDate}`) 
        .then((res) => {
          if (res.data.success) {
            setNbrAppels(res?.data?.receiver_count)
          } 
        });
        setShowAppels(true);
      }
    }
  }, [startDate, endDate]);
  

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return (
    <>
      <ToastContainer />
      <div className="call-history-page">
        <div className="profile-depp-head">
          <div className="kleever-log" onClick={(e) => handlenav(e)}></div>
        </div>
        <div className="middle-div">
          <div className="middle-head">
            <div className="return-control" onClick={(e) => handleBack(e)}>
              <UilArrowLeft size="30" color="black" />
              <span>Return</span>
            </div>
          </div>
          <h3>Historique des appels reçus</h3>
          <div className="date-filter-part">
            <div className="select-date">
              <div className="date-div">
                <img src={Calendar} alt="" />
                <span>Du</span>
                <input
                  type="date"
                  ref={datePickerRef}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="date-div">
                <img src={Calendar} alt="" />
                <span>Au</span>
                <input
                  ref={datePickerRef}
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="calls-total-div">
            {
                showAppels &&
                <h2>{nbrAppels} appels reçus</h2>
            }
          </div>
        </div>
      </div>
    </>
  );
}
