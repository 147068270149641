import React, { useState } from 'react'
import './styles.scss'
import ReactFlagsSelect from "react-flags-select";
import { useEffect } from 'react';
import { ListeOfCountries } from '../data';

export default function CountrySelector({fromProf, country, selected, alreadych}) {
  const [selectedCount, setSelectedCount] = useState("CI");
  useEffect(() => {
    selected(selectedCount);
  }, [selectedCount])

  return (
    <ReactFlagsSelect
    selected={fromProf ? country : selectedCount}
    onSelect={(code) => setSelectedCount(code)}
    customLabels={ListeOfCountries}
    rfsKey={'fr'}
    />
  )
}
