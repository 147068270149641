import React, { useEffect, useState } from "react";
import "./styles.scss";
import { CommentElement, ReservationStripe } from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Star } from "../../assets/images";
import { getAnnonceById } from "../../actions/annonce";
import { PICS_BASE_URL } from "../../components/config/keys";
import { Carousel, Modal } from "react-bootstrap";
import CancelIcon from '@mui/icons-material/Cancel';

export default function VhDetailsPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const Vh = useSelector((state) => state.vheicule.vheicule);
  const ChoosedVh = Vh.filter((item) => item.v_id === id);
  const { spec_annonce } = useSelector((state) => state.annonce);
  const { user } = useSelector((state) => state.user);
  const picsExterArray = JSON.parse(spec_annonce?.pics_exter || "[]");
  const picsInterArray = JSON.parse(spec_annonce?.pics_inter || "[]");
  const combinedArray = picsExterArray.concat(picsInterArray);

  useEffect(() => {
    dispatch(getAnnonceById({ userId: user?.user?.id, annonceId: id }));
  }, []);

  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleImageClick = (item) => {
    setFullscreenImage(item);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  const getNameVh = () => {
    switch (spec_annonce?.type_de_vh) {
      case "voiture":
        return "véhicule";
        break;
      case "bus":
        return "bus";
        break;
      case "moto":
        return "moto";
        break;
      case "camion":
        return "camion";
        break;

      default:
        break;
    }
  };
  return (
    <div className="details-container">
      <div className="first-part">
        <div className="show-vheic-container2">
          <Carousel className="carousel2">
            {combinedArray?.map((item, index) => {
              return (
                <Carousel.Item
                  key={index}
                  onClick={() => handleImageClick(item)}
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url('${PICS_BASE_URL}/${item}')`,
                  }}
                ></Carousel.Item>
              );
            })}
          </Carousel>
          <Modal
            show={fullscreenImage !== null}
            onHide={handleCloseFullscreen}
            centered
          >
            <Modal.Body>
              <CancelIcon className="cancel-modal" onClick={handleCloseFullscreen}/>
              <img
                src={`${PICS_BASE_URL}/${fullscreenImage}`}
                alt="Fullscreen"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Modal.Body>
          </Modal>
        </div>

        <div className="right">
          <div className="details-title">
            <p>{spec_annonce?.title}</p>
          </div>
          <div className="details-specif">
            <p>Caractéristique du {getNameVh()}</p>
            <table>
              {spec_annonce?.type_de_vh == "voiture" && (
                <tr>
                  <td>Modèle de {getNameVh()}</td>
                  <td>{spec_annonce?.model}</td>
                </tr>
              )}
              <tr>
                <td>Marque du {getNameVh()}</td>
                <td>{spec_annonce?.marque}</td>
              </tr>
              {spec_annonce?.type_de_vh == "voiture" && (
                <tr>
                  <td>Boite de vitesse</td>
                  <td>{spec_annonce?.boite_de_vitess}</td>
                </tr>
              )}
              {spec_annonce?.type_de_vh == "voiture" && (
                <tr>
                  <td>Avec ou sans chauffeur ?</td>
                  <td>
                    {spec_annonce?.chauffeur == 0
                      ? "Sans chauffeur"
                      : "Avec chauffeur"}
                  </td>
                </tr>
              )}
              <tr>
                <td>Année du {getNameVh()}</td>
                <td>{spec_annonce?.Annee_vh}</td>
              </tr>
              {spec_annonce?.type_de_vh == "camion" && (
                <tr>
                  <td>Type de {getNameVh()}</td>
                  <td>{spec_annonce?.model}</td>
                </tr>
              )}
              {spec_annonce?.type_de_vh == "voiture" || spec_annonce?.type_de_vh == "bus" && (
                <tr>
                  <td>Nombre de places</td>
                  <td>{spec_annonce?.nbr_places}</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="second-part">
        <div className="description-div">
          <span>Description de l'annonce</span>
          <p>{spec_annonce?.description}</p>
        </div>
        <div className="comment-section">
          <div className="comment-section-head">
            <span>Notes et avis client</span>
            <div className="rate-icon">
              <img src={Star} />
              <span>
                {spec_annonce?.notes == null ? 0 : Number(spec_annonce?.notes)?.toFixed(0)}
              </span>
            </div>
          </div>
          {/* <CommentElement />
          <CommentElement />
           */}
          {spec_annonce?.avis?.map((note_avis) => (
            <CommentElement note_avis={note_avis} />
          ))}
          {spec_annonce?.avis?.length === 0 && (
            <span style={{ padding: "5rem 0rem" }}>
              {"Pas de commentaires"}
            </span>
          )}
          {false && (
            <button className="btn show-all-btn">Voir tous les Avis</button>
          )}
        </div>
      </div>
      <ReservationStripe
        resId={spec_annonce?.id}
        remise={spec_annonce?.remise}
        prix={spec_annonce?.price_day}
      />
    </div>
  );
}
