import React, { useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import "./styles.scss";
import CommentComp from "../commentcomp";
import { useDispatch, useSelector } from "react-redux";
import { IS_RATE_DRAWER } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import Rating from "@mui/material/Rating";

export default function RateDrawer({setDepannersList, userLocation}) {
  const dispatch = useDispatch();
  const [rateNote, setRate] = useState(0); //for rating
  const [commentText, setCommentText] = useState(""); //for rating
  const { datadepnotes, user, id_dep } = useSelector((state) => state.user);
  const hiderate = (e) => {
    e.preventDefault();
    dispatch({ type: IS_RATE_DRAWER, payload: false });
  };

      const handleSendComment = (e) => {
        e.preventDefault();
        let formData = {
          user_id: user?.user?.id,
          note: rateNote,
          comment: commentText,
          dep_id: id_dep,
        };
        apiClient()
          .post("/add-dep-avis-note", formData)
          .then((res) => {
            if (res?.data?.success) {
            //   history.push("/myreservations");
            if (userLocation.lat !== 5.345317 && userLocation.lng !== -4.024429) {
              // dispatch(getListDep({ lat: userLocation?.lat, lng: userLocation?.lng }));
              apiClient()
              .post(`/get-users-distance`, { lat: userLocation?.lat, lng: userLocation?.lng }) //change this /membre/login
              .then((res) => {
                if (res.data.success) {
                  setDepannersList(res.data.users);
                  dispatch({ type: IS_RATE_DRAWER, payload: false });
                } 
              });
            } else {
              apiClient()
              .post(`/get-users-distance`, {lat: 5.345317, lng: -4.024429}) //change this /membre/login
              .then((res) => {
                if (res.data.success) {
                  setDepannersList(res.data.users);
                  dispatch({ type: IS_RATE_DRAWER, payload: false });
                } 
              });
            }
            }
          });
      };
  return (
    <SwipeableDrawer className="rate-drawer" open={true}>
      <div className="rate-container">
        <div className="rate-head">
          <span>Notes et avis clients</span>
          <button className="btn close-btn" onClick={(e) => hiderate(e)}>
            X
          </button>
        </div>
        <div className="comment-cont">
          <div>
            {datadepnotes?.map((dataavis, index) => (
            <CommentComp key={index} note_avis={dataavis} />
          ))}
          </div>
          {datadepnotes?.length === 0 &&
            <div style={{margin: 'auto'}}>Pas de commentaires...</div>
          }
          
          {datadepnotes.some(note => note.user_id === user.user.id) ?
          <div className="end-div">
          <span className="ajouter_title" style={{textAlign: 'center'}}>Note ajoutée.</span>
            {/* <Rating
              name="half-rating"
              value={datadepnotes.some(note => note)}
              precision={1}
            />
            <input
              type="text"
              className="comment_section"
              value={datadepnotes.some(note => note.comment)}
              disabled
            /> */}
        </div> 
        :
          <div className="end-div">
            <span className="ajouter_title">
              Noter le dépanneur
            </span><Rating
              name="half-rating"
              defaultValue={0}
              value={rateNote}
              precision={1}
              onChange={(event, newValue) => setRate(newValue || 0)}
            />
            <input
              onChange={(e) => setCommentText(e.target.value)}
              type="text"
              className="comment_section"
              placeholder="Faire un commentaire..."
            />
            <button
              className="send_comment"
              onClick={(e) => handleSendComment(e)}
            >
              Envoyer
            </button>
          </div> }
        </div>
        
        {/* <div className="give-rate-div">
          <span>Noter le dépanneur</span>
          <button className="btn give-btn">Faire un commentaire …..</button>
        </div> */}
        
      </div>
    </SwipeableDrawer>
  );
}
