import React from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { IS_SIDE_BAR } from '../../constants/actions';
import { KleeverLogo1 } from '../../assets/images';

export default function WelcomePage() {

const history =  useHistory(); 
const dispatch = useDispatch();

const handleGotoFirstPage = () => {
    dispatch({ type: IS_SIDE_BAR, payload: false });
    history.push('/choosepage');
}

    return (
        <div className='welcome-container'>
            <div className='middle-div'>
            <span className='title-kleev'>
                Bienvenue sur
            </span>
            <div className="logo-title">
                <img src={KleeverLogo1} alt="logo_kleever" />
                <span>KLEEVER</span>
            </div>
            <button type="button" 
            className="btn send-btn"
            onClick={()=> handleGotoFirstPage()}
            >Commencer</button>
            </div>
        </div>
    )
}
