import { React, useEffect } from "react";
import { KleeverLogo, KleeverLogoLast } from "../../assets/images";
import { deepOrange } from "@mui/material/colors";
import { useHistory } from "react-router-dom";
// import { useWindowSize } from '../../hooks';
// import Avatar from "@mui/material/Avatar";
import { UilAlignJustify } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { IS_SIDE_BAR, SET_USER } from "../../constants/actions";
import SideBar from "../sidebar";
import { apiClient } from "../../actions/api";

export default function Index() {
  const queryParams = new URLSearchParams(window.location.search);
  const typepush = queryParams.get("typepush");
  const dispatch = useDispatch();
  const token = queryParams.get("token");
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("typepush", typepush);
    }
  }, []);
  const showSideBar = (e) => {
    e.preventDefault();
    apiClient()
    .get(`/user-exact/${user?.user?.id}`)
    .then((res) => {
      if(res?.data?.success){
        dispatch({ type: SET_USER, payload: res.data.user });
    dispatch({ type: IS_SIDE_BAR, payload: true });
      }
    })
  };
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    history.push("/");
  };
  return (
    <>
      <SideBar />
      <div className="navbar navbar-app">
        <div className="container">
          <div className="log-app" onClick={(e) => handleClick(e)}>
            <img src={KleeverLogoLast} alt={"kleever_logo"} />
          </div>
          <div className="page-descriptor">
            <span></span>
          </div>
          <UilAlignJustify
            size="50"
            color="white"
            onClick={(e) => showSideBar(e)}
          />
        </div>
      </div>
    </>
  );
}
