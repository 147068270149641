import React from "react";
import "./styles.scss";

export default function TotalStripe({norml_price, reduc_price}) {

  return (
    <div className="total-stripe-container">
      <div className="left-side">
        {/* <p>Frais de commission Kleever</p> */}
        {/* <span>5%</span> */}
        <span>Total à payer</span>
        <p>
          Ce montant est remboursable en cas d'annulation
          {/* <span> Voir conditions </span> */}
        </p>
      </div>
      <div className="right-side">
        <span style={{textDecoration: norml_price != reduc_price && 'line-through'}}>{norml_price} Fcfa</span>
        <span>{reduc_price} Fcfa</span>
      </div>
    </div>
  );
}
