import { AC_REF_OFFRE_SPEC, COUNTRY_RES, END_DATE, FINAL_PRICE, LIST_REC_OFFRES, LIST_RES_LOCA, NORMAL_PRICE, NUMBER_RES, RED_PRICE, RES_FINAL_DATE, RES_START_DATE, SPECIFIC_RESERVATION, START_DATE } from "../constants/actions";

const defaultState = {
    first_date: '',
    last_date: '',
    final_price: '',
    number_res: '',
    country_res: '',
    list_res_loc: [],
    spec_res_loc: {},
    list_rec_offres: [],
    ac_ref_off_sp: {},
    normal_price_res: '',
    red_price_res: '',
    start_date_res: '',
    end_date_res: '',
}

export default (state = defaultState, action) => {
    const { type, payload = {} } = action
    switch (type) {
        case START_DATE: 
            return{...state,first_date:payload}
        case END_DATE: 
            return{...state,last_date:payload}
        case FINAL_PRICE: 
            return{...state,final_price:payload}
        case NUMBER_RES: 
            return{...state,number_res:payload}
        case COUNTRY_RES: 
            return{...state,country_res:payload}
        case LIST_RES_LOCA:
            return{...state,list_res_loc:payload}
        case SPECIFIC_RESERVATION:
            return{...state,spec_res_loc:payload}
        case LIST_REC_OFFRES:
            return{...state,list_rec_offres:payload}
        case AC_REF_OFFRE_SPEC:
            return{...state,ac_ref_off_sp:payload}
        case NORMAL_PRICE:
            return{...state,normal_price_res:payload}
        case RED_PRICE:
            return{...state,red_price_res:payload}
        case RES_START_DATE:
            return{...state,start_date_res:payload}
        case RES_FINAL_DATE:
            return{...state,end_date_res:payload}
        default:
            return state
    }
}
