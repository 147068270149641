import React, { useState } from 'react'
import './styles.scss'
import { Star, UserPng } from '../../assets/images'
import { PICS_BASE_URL } from '../config/keys';
import { apiClient } from '../../actions/api';
import { useSelector } from 'react-redux';

export default function CommentComp({note_avis}) {
    const createdDate = new Date(note_avis?.created_at);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(createdDate);
    const profilePictureUrl = note_avis?.user?.profile_picture
    ? `${PICS_BASE_URL}/${note_avis?.user?.profile_picture}`.replace('//images', '/images')
    : UserPng;



  return (
    <div className='comment-comp'>
        <div className='avatar-div'>
        <img className='avatar_pic_comment' src={profilePictureUrl}/>
        </div>
        <div className='comment-div'>
            <div className='comment-head'>
                <div className='rate-part'>
                    <img src={Star} />
                    <span>{note_avis?.note}</span>
                </div>
                <div className='time-part'>
                    <span>
                    {/* Il y a deux jours */}
                    {formattedDate}
                    </span>
                </div> 
            </div>
            <div className='comment-body'>
                <p>{note_avis?.comment}</p>
                <span>Poste Par <a>{note_avis?.user?.firstName} {note_avis?.user?.lastName}</a></span>
            </div>
                   
        </div>
    </div>
  )
}
