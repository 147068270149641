import React from 'react'
import './styles.scss'
import {SwipeableDrawer} from '@mui/material';
import { DoneImg, MarkerImg } from '../../assets/images';
import { useDispatch } from 'react-redux';
import { IS_POS_DRAWER } from '../../constants/actions';
export default function ChangePosComp() {
    const dispatch = useDispatch(); 
    const closePosDrawer = (e)=>{
       e.preventDefault();
        dispatch({type:IS_POS_DRAWER , payload:false});
      }
    return (
        <SwipeableDrawer className='change-pos-drawer' open={true}>
        <div className='change-pos-comp'>
            <span>Mise à jour de votre position avec succès !</span>
            <div className='done-div'>
            <img src={DoneImg} alt='' />
            </div>
            {/* <div className='marker-div'>
                <img src={MarkerImg} alt='' />
                <span>Abidjan, 925P+48Q</span>
            </div> */}
            <button className='btn close-btn'
            onClick={(e)=>closePosDrawer(e)}
            >
                Fermer
            </button>
        </div>
        </SwipeableDrawer>
    )

}
