import React, { useEffect, useState } from "react";
import "./styles.scss";
import { DeppaneurImg, MarkerImg, Star, UserPng } from "../../assets/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DATA_DEP_ID,
  DATA_DEP_NOTES,
  IS_RATE_DRAWER,
} from "../../constants/actions";
import { PICS_BASE_URL } from "../config/keys";
import { apiClient } from "../../actions/api";
import moment from "moment";
import PlaceIcon from "@mui/icons-material/Place";

export default function DeppaneurCard({ data }) {
  const { user } = useSelector((state) => state.user);
  const [photos1, setPhotos1] = useState()
  const dispatch = useDispatch();
  const handleNav = (e) => {
    e.preventDefault();
    dispatch({ type: DATA_DEP_NOTES, payload: data?.avisdep });
    dispatch({ type: DATA_DEP_ID, payload: data?.id });
    dispatch({ type: IS_RATE_DRAWER, payload: true });
  };
  const handleCallDep = (e) => {
    e.preventDefault();
    const params = {
      caller_id: user?.user?.id,
      receiver_id: data?.id,
      call_time: moment().format("YYYY-MM-DD"),
    };
    apiClient()
      .post(`/call-history/create`, params)
      .then((res) => {
        if (res.data.success) {
          const phoneLink = `tel:${data?.phoneNumber}`;
          window.location.href = phoneLink;
        }
      });
  };
  useEffect(() => {
    setPhotos1(data?.dep_picture ? (data?.dep_picture?.includes('static') ? UserPng : `${PICS_BASE_URL}/${data?.dep_picture?.replace('//images', '/images')}`) : UserPng);
  }, [])

  return (
    <div className="deppaneur-card">
      <div className="depp-card-body">
        <div className="depp-card-head">
          <img
            src={photos1}
            id="dep-img"
            alt="img-depp"
          />

          <div className="infos-div">
            <span>{data?.service_name == "" ? "N/A" : data?.service_name}</span>
            <div className="rate-part">
              <img src={Star} />
              <span>{data?.rate}</span>
            </div>
            <Link onClick={(e) => handleNav(e)}>Voir les notes</Link>
          </div>
        </div>
        <div className="middle-card">
          {/* <img src={MarkerImg} alt='' />  */}
          <PlaceIcon />
          <span>{data?.distance?.toFixed(0)} Km</span>
        </div>
        <button className="btn depp-btn" onClick={(e) => handleCallDep(e)}>
          Appeler
        </button>
      </div>
    </div>
  );
}
