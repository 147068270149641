import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { UilAngleRightB } from "@iconscout/react-unicons";
import { UilUserSquare } from "@iconscout/react-unicons";
import "./styles.scss";
import { EmailXd, MarkerImg, PhoneXd, Wathsapp } from "../../assets/images";
import { Link, useHistory } from "react-router-dom";
import { ChangePosComp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATE_USER, IS_POS_DRAWER } from "../../constants/actions";
import { logoutFunct } from "../../actions/user";
import { PICS_BASE_URL } from "../../components/config/keys";
import { apiClient } from "../../actions/api";
import PlaceIcon from '@mui/icons-material/Place';
import { UserPng } from "../../assets/images";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function ProfileDepp() {
  const { positiondepp, typechoice } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [deppstate, setdeppState] = useState(user?.user?.actif ? "Ouvert" : "Fermé");
  const phoneNumberKleev = "+2250712530418"

  let formData = {
    userId: user?.user?.id,
    actif: '',
  };

  const handledeppstate = (e) => {

    if (deppstate === "Ouvert") {
      setdeppState("Fermé");
      formData.actif = false
    } else {
      setdeppState("Ouvert");
      formData.actif = true
    }

    apiClient()
        .post('/update-statut', formData)
        .then((res) => {
          if(res.data.success){
            dispatch({ type: AUTHENTICATE_USER, payload: res?.data });
          }
        });
    
  };

  const handleNav = (goto) => {
    history.push("/" + goto);
  };
  const showPosDrawer = (e) => {
    e.preventDefault();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        let formData = {
          userId: user?.user?.id,
          lat: latitude,
          lng: longitude,
        }
        apiClient()
        .post('/update-geo-loc', formData)
        .then((res) => {
          if(res.data.success){
            dispatch({ type: IS_POS_DRAWER, payload: true });
          }
        });
      },
      (error) => {
        console.error("Error getting user's location:", error.message);
        // Handle error, if needed
      }
    );
    
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutFunct());
    history.push("/");
  };

  const handleCallButtonClick = () => {
    const phoneLink = `tel:${phoneNumberKleev}`;
    window.location.href = phoneLink;
  };

  const handleWhatsAppButtonClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumberKleev}`;

    window.location.href = whatsappLink;
  };


  const handleSendMail = () => {
    const email = 'contact@kleeverapp.com';
    const subject = "Besoin d'aide - Dépanneurs sur Kleever";
    const body = "J'utilise actuellement l'application Kleever et j'ai besoin d'aide. J'ai rencontré un problème et j'aurais besoin de l'assistance";

    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
  };

  return (
    <>
      {positiondepp && <ChangePosComp />}
      <div className="profile-depp">
        <div className="profile-depp-head">
          <div className="kleever-log" onClick={() => history.push('/showall/'+typechoice+'/page=1')}></div>
        </div>
        <div className="setting-part">
          <div className="setting-part-head">
            <div
              className="profile-pic"
              style={{
                backgroundImage: `url(${user?.user?.profile_picture ? (user?.user?.profile_picture?.includes('static') ? `https://kleeverapp.com/${UserPng}` : `${PICS_BASE_URL}/${user?.user?.profile_picture?.replace('//images', '/images')}`) : `https://kleeverapp.com/${UserPng}`})`,
              }}
            ></div>
            <span>{user?.user?.service_name == '' ? 'N/A' : user?.user?.service_name}</span>
            <div className="check-div">
              <Form.Check
                type="switch"
                id="custom-switch"
                onClick={(e) => handledeppstate(e)}
                checked={deppstate === "Fermé"}
              />
              <span>{deppstate}</span>
            </div>
          </div>
          <div className="setting-nav-part">
            <div className="nav-item" onClick={() => handleNav("deppinfos")}>
              <UilUserSquare size="40" color="gray" />
              <span>Mes informations</span>
              <UilAngleRightB size="25" color="white" />
            </div>
            <div className="nav-item" onClick={() => handleNav("callhistory")}>
              <UilUserSquare size="40" color="gray" />
              <span>Historique des appels</span>
              <UilAngleRightB size="25" color="white" />
            </div>
            <button
              className="btn refresh-pos"
              onClick={(e) => showPosDrawer(e)}
            >
              <PlaceIcon />
              Actualiser ma géolocalisation actuelle
            </button>
          </div>
          <div className="bottom-setting-part">
            <span>Besoin d'aide ? Contactez le service client !</span>
            <div className="contact-div">
              <CallIcon onClick={handleCallButtonClick} />
              <img onClick={handleWhatsAppButtonClick} src={Wathsapp} alt="" />
              <MailOutlineIcon onClick={handleSendMail}/> 
            </div>
            <span className="logout" onClick={(e) => handleLogout(e)}>
              Se déconnecter
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
