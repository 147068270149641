import React, { useEffect, useState } from "react";
import "./styles.scss";
import { PaginComp, VheiReservComp } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationsLocat,
  specificReservation,
} from "../../actions/reservation";

export default function AllReservPage() {
  const [indexbtn, setIndexbtn] = useState(1);
  const { list_res_loc } = useSelector((state) => state.reservation);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sortedListResLoc, setSortedListResLoc] = useState([])
  
  useEffect(() => {
    setSortedListResLoc(list_res_loc.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    }))
  }, [list_res_loc])

  const handlebtn = (index) => {
    setIndexbtn(index);
  };

  useEffect(() => {
    dispatch(getReservationsLocat(user?.user?.id));
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleChooseRes = (item) => {
    if(item?.status_suivi !== -1){
    dispatch(specificReservation(item));
    history.push("/reservstatus/"+item?.id);
    }
  };

  const getEtat = (eta) => {
    switch (eta) {
      case 1:
        return "Validation en cours"; //En cours de validation
      case 2:
        return "Accepté"; //En attente de paiement
      case 3:
        return "Remise de clés en cours....";
      case 4:
        return "Terminée";
      case -1:
        return "Réservation refusée";
      default:
        break;
    }
  };

  return (
    <div className="all-reserv-container">
      <div className="tab-nav-div">
        <button
          className="btn"
          id={indexbtn === 1 && "activebtn"}
          onClick={() => handlebtn(1)}
        >
          En cours....
        </button>
        <button
          className="btn"
          id={indexbtn === 2 && "activebtn"}
          onClick={() => handlebtn(2)}
        >
          Terminées
        </button>
      </div>
      <div className="all-res-body">
        {indexbtn === 1
          ? sortedListResLoc
              .filter((item) => item?.status_suivi !== 4)
              .map((item) => (
                <div onClick={() => handleChooseRes(item)}>
                  <span>{formatDate(item?.created_at)}</span>
                  <VheiReservComp
                    data={item}
                    statu={getEtat(
                      item?.status_suivi === 0 ? 1 : item?.status_suivi
                    )}
                    goto={item?.status_suivi === 0 ? 1 : item?.status_suivi}
                  />
                </div>
              ))
          : list_res_loc
              .filter((item) => item?.status_suivi === 4)
              .map((item) => (
                <div onClick={() => handleChooseRes(item)}>
                  <span>{formatDate(item?.created_at)}</span>
                  <VheiReservComp
                    data={item}
                    statu={getEtat(
                      item?.status_suivi === 0 ? 1 : item?.status_suivi
                    )}
                    goto={item?.status_suivi === 0 ? 1 : item?.status_suivi}
                  />
                </div>
              ))}

        {list_res_loc?.length == 0 && (
          <span style={{ margin: "auto" }}>Pas de réservations</span>
        )}
      </div>
      {/* <PaginComp /> */}
    </div>
  );
}
