import { AUTHENTICATE_USER, IS_SUBMITED, IS_ERROR, LIST_DEP, INSC_CODE, INSC_NAME, INSC_PHONE, INSC_EMAIL, INSC_COUNTRY, INSC_PASSWORD } from "../constants/actions";
import { getMesAnnonce } from "./annonce";
import { apiClient } from "./api";

// this function allows to create a user in the database
export const registerUser = (params) => (dispatch) => {
  const data = {
    ...params,
  };

  if(localStorage.getItem('token')){
    if(localStorage.getItem('typepush')=='ios'){
     data.push_ios=localStorage.getItem('token');
    }
   else{
      data.push_android=localStorage.getItem('token');
    }
}

  return apiClient()
    .post(`/register`, data)
    .then((res) => {
      if (res.data.success === true) {
        dispatch({ type: AUTHENTICATE_USER, payload: res.data });
        dispatch(getMesAnnonce({userId: res?.data?.user?.id}))
      } else {
        const error = res.data.errors;
        if (error) {
          throw new Error(error);
        }
      }
    })
    .catch((error) => {
      throw error; // Rethrow the error for better error propagation
    });
};


export const loginUser = (params) => (dispatch) => {
  const data = {
    ...params,
  };
   if(localStorage.getItem('token')){
       if(localStorage.getItem('typepush')=='ios'){
        data.push_ios=localStorage.getItem('token');
       }
      else{
         data.push_android=localStorage.getItem('token');
       }
   }

  return apiClient()
    .post(`/login`, data) //change this /membre/login
    .then((res) => {
      if (res.data.success === true) {
        dispatch({ type: AUTHENTICATE_USER, payload: res.data });
        dispatch(getMesAnnonce({userId: res?.data?.user?.id}))
      } else {
        const error = res.data.error;
        if (error.email) {
          throw new Error("l'email ou le mot de passe est incorrect");
        } else {
          throw new Error("l'email ou le mot de passe est incorrect");
        }
      }
    });
};

export const logoutFunct = () => (dispatch) => {
  dispatch({ type: AUTHENTICATE_USER, payload: ''})
}

export const getListDep = (params) => (dispatch) => {
  const data = {
    ...params,
  };

  return apiClient()
    .post(`/get-users-distance`, data) //change this /membre/login
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: LIST_DEP, payload: res.data.users });
      } 
    });
};


export const save_insc_name = (name) => (dispatch) => {
  dispatch({type: INSC_NAME, payload: name})
}

export const save_insc_phone = (phone) => (dispatch) => {
  dispatch({type: INSC_PHONE, payload: phone})
}

export const save_insc_email = (email) => (dispatch) => {
  dispatch({type: INSC_EMAIL, payload: email})

}

export const save_insc_country = (country) => (dispatch) => { 
  dispatch({type: INSC_COUNTRY, payload: country})
}

export const save_insc_code = (code) => (dispatch) => {
  dispatch({type: INSC_CODE, payload: code})
}

export const save_insc_password = (password) => (dispatch) => {
  dispatch({type: INSC_PASSWORD, payload: password})
} 

