import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { IS_DRAWER } from "../../constants/actions";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeReservation, saveCountryRes, saveNumberRes } from "../../actions/reservation";
import moment from 'moment';

export default function ReservInfosPage() {
  const dispatch = useDispatch();
  const [country, setCountry] = useState(countryList().data);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user)
  const { spec_annonce } = useSelector((state) => state.annonce);
  const { first_date, last_date, final_price, number_res, country_res } = useSelector((state) => state.reservation);
  const [phone1, setPhone1] = useState(user?.user?.phoneNumber);
  const [phone2, setPhone2] = useState(user?.user?.phoneNumber);
  const [selectedCountry, setSelectedCountry] = useState(country?.find((item) => item.value === user?.user?.country));
  
  // const countryObjects = country?.map((item) => {
  //   return { label: item };
  // });
  const checkValueConf = () => {
    if(phone1 !== '' && phone2 !== '' && selectedCountry !== ''){
      return 'checkVal-conf';
    }
  }

  const handleComp = (e) => {
    e.preventDefault();
    if (phone1 !== phone2) {
      setError("Les numéros de téléphone ne correspondent pas.");
    } else {
      dispatch(saveCountryRes(selectedCountry.value))
      dispatch(saveNumberRes(phone1))
      setIsLoading(true);
      let formData = {
      'id_locataire' : user?.user?.id,
      'id_annonceur' : spec_annonce?.id_owner,
      'id_annonce' : spec_annonce?.id,
      'date_debut' : first_date,
      'date_fin' : last_date,
      'prix_final' : final_price,
      'phone' : phone1,
      'country' : selectedCountry?.value,
      'date_payment': moment().format('YYYY-MM-DD'),
      }
      dispatch(makeReservation(formData, user?.user?.id))
      .then(() => {
        dispatch({ type: IS_DRAWER, payload: true });
      })
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);
  
  const SelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span>{label}</span>
        <button className="btn">Changer</button>
      </div>
    );
  };
  
  const options = country?.map((item) => {
    return { value: item.value, label: <SelectOption label={item.label} /> };
  });

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  
  return (
    <div className="reserve-info-page">
      <ToastContainer />
      <h3>Vos cordonnées</h3>
      <div className="top-div">
        <span>Pays d'habitation</span>
        <div className="country-pick">
          <Select
            options={options}
            value={selectedCountry}
            onChange={handleCountryChange}
            className="country-select"
          />
        </div>
      </div>
      <PhoneInput
        className="phone-picker"
        country={"ci"}
        value={phone1}
        autoFormat={false}
        onChange={(value) => setPhone1(value)}
      />
      <PhoneInput
        className="phone-picker"
        country={"ci"}
        value={phone2}
        autoFormat={false}
        onChange={(value) => setPhone2(value)}
      />
      <div className="accept-div">
        <input type="checkbox" />
        <p>Enregistrer mes informations pour la prochaine fois</p>
      </div>
      <button className={`btn confirme-btn ${checkValueConf()}`} onClick={(e) => handleComp(e)}>
        Confirmer
      </button>
    </div>
  );
}
