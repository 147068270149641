import React from "react";
import { SwipeableDrawer } from "@mui/material";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { IS_MARQUE_DRAWER, VH_MARQUE } from "../../constants/actions";
export default function VhMarqueDrarwer() {
  const SimpleSelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };
  const dispatch = useDispatch();
  const hidedrawer = (e) => {
    e.preventDefault();
    dispatch({ type: IS_MARQUE_DRAWER, payload: false });
  };
  const setmarque = (marque, e) => {
    dispatch({ type: VH_MARQUE, payload: marque });
    hidedrawer(e);
  };
  const vhmarque = [
    {
      value: "Toyota",
      label: <SimpleSelectOption value="Toyota" label="Toyota" />,
    },
    {
      value: "Volkswagen",
      label: <SimpleSelectOption value="Volkswagen" label="Volkswagen" />,
    },
    {
      value: "Ford",
      label: <SimpleSelectOption value="Ford" label="Ford" />,
    },
    {
      value: "Nissan",
      label: <SimpleSelectOption value="Nissan" label="Nissan" />,
    },
    {
      value: "Chevrolet",
      label: <SimpleSelectOption value="Chevrolet" label="Chevrolet" />,
    },
    {
      value: "Honda",
      label: <SimpleSelectOption value="Honda" label="Honda" />,
    },
    {
      value: "BMW",
      label: <SimpleSelectOption value="BMW" label="BMW" />,
    },
    {
      value: "Mercedes-Benz",
      label: <SimpleSelectOption value="Mercedes-Benz" label="Mercedes-Benz" />,
    },
    {
      value: "Audi",
      label: <SimpleSelectOption value="Audi" label="Audi" />,
    },
    {
      value: "Hyundai",
      label: <SimpleSelectOption value="Hyundai" label="Hyundai" />,
    },
    {
      value: "Kia",
      label: <SimpleSelectOption value="Kia" label="Kia" />,
    },
    {
      value: "Porsche",
      label: <SimpleSelectOption value="Porsche" label="Porsche" />,
    },
    {
      value: "Tesla",
      label: <SimpleSelectOption value="Tesla" label="Tesla" />,
    },
    {
      value: "Jaguar",
      label: <SimpleSelectOption value="Jaguar" label="Jaguar" />,
    },
    {
      value: "Land Rover",
      label: <SimpleSelectOption value="Land Rover" label="Land Rover" />,
    },
    {
      value: "Volvo",
      label: <SimpleSelectOption value="Volvo" label="Volvo" />,
    },
    {
      value: "Mazda",
      label: <SimpleSelectOption value="Mazda" label="Mazda" />,
    },
    {
      value: "Renault",
      label: <SimpleSelectOption value="Renault" label="Renault" />,
    },
    {
      value: "SEAT",
      label: <SimpleSelectOption value="SEAT" label="SEAT" />,
    },
    {
      value: "Škoda",
      label: <SimpleSelectOption value="Škoda" label="Škoda" />,
    },
    {
      value: "Fiat",
      label: <SimpleSelectOption value="Fiat" label="Fiat" />,
    },
    {
      value: "Mitsubishi",
      label: <SimpleSelectOption value="Mitsubishi" label="Mitsubishi" />,
    },
    {
      value: "Citroën",
      label: <SimpleSelectOption value="Citroën" label="Citroën" />,
    },
    {
      value: "Lexus",
      label: <SimpleSelectOption value="Lexus" label="Lexus" />,
    },
    {
      value: "Mini",
      label: <SimpleSelectOption value="Mini" label="Mini" />,
    },
    {
      value: "Subaru",
      label: <SimpleSelectOption value="Subaru" label="Subaru" />,
    },
    {
      value: "Alfa Romeo",
      label: <SimpleSelectOption value="Alfa Romeo" label="Alfa Romeo" />,
    },
    {
      value: "Infiniti",
      label: <SimpleSelectOption value="Infiniti" label="Infiniti" />,
    },
    {
      value: "Lotus",
      label: <SimpleSelectOption value="Lotus" label="Lotus" />,
    },
    {
      value: "Dacia",
      label: <SimpleSelectOption value="Dacia" label="Dacia" />,
    },
    {
      value: "Maserati",
      label: <SimpleSelectOption value="Maserati" label="Maserati" />,
    },
    {
      value: "Aston Martin",
      label: <SimpleSelectOption value="Aston Martin" label="Aston Martin" />,
    },
    {
      value: "Ferrari",
      label: <SimpleSelectOption value="Ferrari" label="Ferrari" />,
    },
    {
      value: "Lamborghini",
      label: <SimpleSelectOption value="Lamborghini" label="Lamborghini" />,
    },
    {
      value: "Rolls-Royce",
      label: <SimpleSelectOption value="Rolls-Royce" label="Rolls-Royce" />,
    },
    {
      value: "Bugatti",
      label: <SimpleSelectOption value="Bugatti" label="Bugatti" />,
    },
    {
      value: "McLaren",
      label: <SimpleSelectOption value="McLaren" label="McLaren" />,
    },
    {
      value: "Bentley",
      label: <SimpleSelectOption value="Bentley" label="Bentley" />,
    },
    {
      value: "GMC",
      label: <SimpleSelectOption value="GMC" label="GMC" />,
    },
    {
      value: "Hummer",
      label: <SimpleSelectOption value="Hummer" label="Hummer" />,
    },
    {
      value: "Opel",
      label: <SimpleSelectOption value="Opel" label="Opel" />,
    },
    {
      value: "Peugeot",
      label: <SimpleSelectOption value="Peugeot" label="Peugeot" />,
    },
    {
      value: "Suzuki",
      label: <SimpleSelectOption value="Suzuki" label="Suzuki" />,
    },
    {
      value: "Smart",
      label: <SimpleSelectOption value="Smart" label="Smart" />,
    },
    {
      value: "SsangYong",
      label: <SimpleSelectOption value="SsangYong" label="SsangYong" />,
    },
    {
      value: "Isuzu",
      label: <SimpleSelectOption value="Isuzu" label="Isuzu" />,
    },
    {
      value: "Autre",
      label: <SimpleSelectOption value="Autre" label="Autre" />,
    },
  ];
  
  return (
    <SwipeableDrawer className="vhmarque-drawer" open={true}>
      <div className="rate-head">
        <span>Marques de véhicules</span>
        <button className="btn close-btn" onClick={(e) => hidedrawer(e)}>
          X
        </button>
      </div>
      <div className="vhmarque-body">
        {vhmarque?.map((item) => {
          return (
            <div onClick={(e) => setmarque(item.value, e)}>{item.label}</div>
          );
        })}
      </div>
    </SwipeableDrawer>
  );
}
