import { combineReducers } from 'redux'
import user from './user'
import global from './global'
import vheicule from './vheicule'
import annonce from './annonce'
import reservation from './reservation'

const rootReducer = combineReducers({
    user,
    global,
    vheicule,
    annonce,
    reservation
})

export default rootReducer;