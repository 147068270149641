import React from 'react'
import './styles.scss'  ;
import { EclipseImg,KleeverMainLogo } from '../../assets/images';
import { useHistory } from 'react-router-dom';
export default function MainPage() {
 const history =  useHistory() ; 
  const handleNav =(e)=>{
    e.preventDefault(); 
    history.push('/auth' )
  }
  return (
    <div className='main-container'>
      <div className='kleever-logo'>
        <img src={KleeverMainLogo} alt=''/>
      </div>
      <span>KLEE<span>VER</span></span>
        <span>{"Louez vos véhicules en un clic !"}</span>
        <div className="for-shdw"></div>
        <div className='cars-div'>
          <div className='cars-element'>
          </div>
          <div className="cont-take-way">
            <button className='btn take-way-btn'
          onClick={(e)=>handleNav(e)}
          >
          Prenez la route ..
          </button>
          </div>
          
        </div>
        <img src={EclipseImg} className='eclipse' alt='eclipse-img'/>
    </div>
  )
}
