import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import "./styles.scss";
import { useSelector } from "react-redux";

export default function SliderComp({ priceFr, priceT }) {
  const { filter_max_value } = useSelector((state) => state.annonce)
  let maxval = filter_max_value ? parseInt(filter_max_value) : 935000;
  const [value, setValue] = useState([0, maxval]); //filter_max_value !== "" ? filter_max_value : 45000

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const [priceFrom, priceTo] = value;
    priceFr(parseInt(priceFrom));
    priceT(parseInt(priceTo));
  }, [value])

  return (
    <Slider
      value={value}
      onChange={handleChange}
      color="secondary"
      step={500} // Set the step size to 100
      min={0}
      max={maxval} //filter_max_value !== "" ? filter_max_value : 45000
    />
  );
}
