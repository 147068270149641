import React from 'react'
import './styles.scss';
import { useHistory } from 'react-router-dom';
export default function DoneReservPage() {
    const history = useHistory()  ;
    const handleNav  = (e)=>{
        e.preventDefault();
        history.push('/myreservations')
    }
    const handleBack  = (e)=>{
        e.preventDefault();
        history.push('/showall/voiture/page=1')
    }
    return (
        <div className='done-container'>
            <h3>Réservation Confirmée !</h3>
            <div className='msg-div'>
                <p>
                    En attente de l'approbation de l'annonceur Délai d'attente entre   
                    <span>
                       {'  10 minutes - 2 heures.'}
                    </span>
                </p>
            </div>
            <div className='buttons-div'> 
                <button className='btn'
                onClick={(e)=>handleNav(e)}
                >
                Voir ma réservation
                </button>
                <button className='btn'
                onClick={(e)=>handleBack(e)}
                >
                Retourner dans mes recherches
                </button>
            </div>
        </div>
    )
}