import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDescriptionOfVh, getTitleOfVh } from "../../actions/annonce";

export default function SecondPubPage() {
  const { 
    title,
    description,
  } = useSelector((state) => state.annonce)

  const dispatch = useDispatch();
  const [titleAnnonce, setTitleAnnonce] = useState(title);
  const [descriptionText, setDescriptionText] = useState(description);


  useEffect(() => {
    dispatch(getTitleOfVh(titleAnnonce));
  }, [titleAnnonce]);

  useEffect(() => {
    dispatch(getDescriptionOfVh(descriptionText));
  }, [descriptionText]);

return (
  <>
  <div className="infos-div">
    <span>Titre de l'annonce</span>
    <input
      type="text"
      value={titleAnnonce}
      onChange={(e) => setTitleAnnonce(e.target.value)}
    />
  </div>
  <div className="infos-div">
    <span>Description de votre annonce</span>
    {/* <input className='description-input' placeholder='Ecrire ici .....' /> */}
    <textarea
      class="form-control"
      className="description-input"
      id="exampleFormControlTextarea1"
      rows="3"
      value={descriptionText}
      onChange={(e) => setDescriptionText(e.target.value)}
    ></textarea>
  </div>
</>
)

}
