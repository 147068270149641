import React from 'react' ; 
import './styles.scss' ; 
import { UilArrowLeft } from '@iconscout/react-unicons'
import { useHistory } from 'react-router-dom';
export default function NavHeader() {
 const history = useHistory();
  const handleBack = ()=>{
    history.goBack();
}
  return (
    <div className='nav-header'>
      <button
      onClick={
        ()=>handleBack()
      }
      >
            <UilArrowLeft
            Size = '50' 
            color = 'grey' 
            />         
      </button>
    </div>
  )
}